import React, { useEffect, useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetByIdWithdrawlQuery,
  useUpdatedWithdrawlMutation,
} from "../../App/Slice/withdrawlSlice";

function UpdateWithdrawl() {
  const params = useParams();
  const { id } = params;

  const [status, setStatus] = useState();
  const [mode, setMode] = useState();
  const [amount, setAmount] = useState();
  const [orderID, setOrderId] = useState();
  const [byBank, setByBank] = useState([]);
  const [byUpi, setByUPI] = useState([]);

  const { data } = useGetByIdWithdrawlQuery(id);
  const withdrawMoney = data?.withdraw_money;

  const [updatedWithdrawl] = useUpdatedWithdrawlMutation();

  const updateHandlerForm = async (e) => {
    e.preventDefault();
    const data = { status: status };
    const response = await updatedWithdrawl({ id, data });
    console.log("update withdrawl data :", response);
    const result = await response?.data;
    if (result?.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href="/withdrawl-money"
      }, 1000);
    }
  };

  useEffect(() => {
    const { status, by_bank, by_upi, mode, amount, Order_ID } =
      withdrawMoney || {};
    setStatus(status);
    setByBank(by_bank);
    setByUPI(by_upi);
    setAmount(amount);
    setMode(mode);
    setOrderId(Order_ID);
  }, [params, withdrawMoney]);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <HeaderBtn urls="/withdrawl-money" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Withdrawl Money Request</h4>
                  </div>
                  <div className="global_form_style">
                    <form onSubmit={updateHandlerForm}>
                      <div className="row w-85 mx-auto">
                        <h6 className="mt-3 mb-1">
                          SUB ID: {withdrawMoney?.user?.sub_id} || User Name:{" "}
                          {withdrawMoney?.user?.username}
                        </h6>
                        <h6 className="mt-1 mb-3">MODE : {mode}</h6>
                        <div className="col-4">
                          <div className="input-details">
                            <label>Withdrawl Money Request Status</label>
                            <select onChange={(e) => setStatus(e.target.value)}>
                              <option value="select status" disabled selected>
                                {status}
                              </option>
                              <option value="pending">pending</option>
                              <option value="approve">approve</option>
                              <option value="rejected">rejected</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-details">
                            <label>Amount</label>
                            <input
                              type="text"
                              disabled
                              style={{
                                cursor: "no-drop",
                                color: "red",
                                border: "1px solid red",
                                background: "#ffb2b2",
                              }}
                              value={amount}
                            />
                          </div>
                        </div>
                        {mode === "UPI" ? (
                          <>
                            <div className="col-4">
                              <div className="input-details">
                                <label>UPI Details</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byUpi?.upi_detail}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>UPI Details</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byUpi?.phone}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Order ID</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={orderID}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Country name</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.country_name}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Account Number </label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.account_no}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Bank Name</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.bank_name}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Bank City</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.bank_city}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Bank Branch</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.bank_branch}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Bank IFSC Code</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.ifsc_code}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>PAN Card</label>
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    cursor: "no-drop",
                                    color: "red",
                                    border: "1px solid red",
                                    background: "#ffb2b2",
                                  }}
                                  value={byBank?.pan_number}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-details">
                                <label>Address</label>
                                <p>{byBank?.address}</p>
                              </div>
                            </div>
                          </>
                        )}
                        {mode === "UPI" ? (
                          <>
                            <div className="col-4"></div>
                            <div className="col-4"></div>
                          </>
                        ) : (
                          <div className="col-4"></div>
                        )}
                        <div className="col-3 mt-4 mb-4">
                          <button type="submit" className="global_form_button">
                            Update Cashback Status
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateWithdrawl;
