import React, { useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { FiXCircle } from "react-icons/fi";
import { useGetCampaignQuery } from "../../App/Slice/campaignSlice";
import { toast } from "react-toastify";
import { useGetsubCategoryQuery } from "../../App/Slice/subCategorySlice";
import { useCreateCouponMutation } from "../../App/Slice/couponSlice";

function AddCoupon() {
  const [title, setTitle] = useState();
  const [coupon_offer, setCouponOffer] = useState();
  const [code, setCode] = useState();
  const [valid_date, setValidData] = useState();
  const [client, setClient] = useState();
  const [category, setCategory] = useState();
  const [keyword, setKeyword] = useState();
  const [tAndc, setTandC] = useState();
  const [link, setLink] = useState();
  const [description, setDescription] = useState();

  const { data: SubCat } = useGetsubCategoryQuery();
  const sub_category = SubCat?.sub_category;

  //create coupon
  const [createCoupon, isLoading] = useCreateCouponMutation();
  const loading = isLoading?.isLoading;

  //get client
  const { data: clientData } = useGetCampaignQuery();
  const campaigns = clientData?.client;

  //keyword Arrary Add
  const [keywordData, setKeywordData] = useState([]);
  const keywordHandler = () => {
    const fe = keyword;
    const addKeyword = [...keywordData, fe];
    setKeywordData(addKeyword);
  };
  //keyword Array Delete
  const keywordDelete = (id) => {
    const deleteKeyword = keywordData.filter((ele, ind) => {
      return ind !== id;
    });
    setKeywordData(deleteKeyword);
  };

  //terms condition Arrary Add
  const [termsC, setTermsC] = useState([]);
  const termsHandler = () => {
    const fe = tAndc;
    const addKeyword = [...termsC, fe];
    setTermsC(addKeyword);
  };
  //keyword Array Delete
  const termsDeleteHandler = (id) => {
    const deleteKeyword = termsC.filter((ele, ind) => {
      return ind !== id;
    });
    setTermsC(deleteKeyword);
  };

  const createPostHandler = async (e) => {
    e.preventDefault();
    const data = {
      title: title,
      coupon_offer: coupon_offer,
      valid_date: valid_date,
      client: client,
      category: category,
      keyword: keywordData,
      terms_condition: termsC,
      description: description,
      coupon_input: code,
      link: link,
    };
    const result = await createCoupon(data);
    const message = await result?.data;
    if (message.status === "failed") {
      toast.error(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/all-coupons";
      }, 1000);
    }
  };

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <HeaderBtn urls="/all-coupons" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Add New Coupon</h4>
                    <p>Get real time reporting of integrated campaigns</p>
                  </div>
                  <div className="global_form_style">
                    <form onSubmit={createPostHandler}>
                      <div className="row w-85 mx-auto">
                        <div className="col-4">
                          <div className="input-details">
                            <label>Campaign Name</label>
                            <select onChange={(e) => setClient(e.target.value)}>
                              <option value="select Campaign" disabled selected>
                                select Campaign
                              </option>
                              {campaigns?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-details">
                            <label>Category</label>
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <input
                                type="text"
                                placeholder="search category name"
                              />
                              <option value="select category" disabled selected>
                                select category
                              </option>
                              {sub_category?.map((val, ind) => {
                                return (
                                  <option value={val._id}>{val.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="input-details">
                            <label>Coupon Title</label>
                            <p>
                              <span className="text-danger fw-bold">Note:</span>{" "}
                              Ex: cashdhundo big bold sale ( started form 7th Dec )
                              - Get 50% - 90% OFF
                            </p>
                            <input
                              type="text"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-4">
                          <div className="input-details">
                            <label>Coupon Offer</label>
                            <p>
                              <span className="text-danger fw-bold">Note:</span>{" "}
                              Ex: Flat 90%
                            </p>
                            <input
                              type="text"
                              onChange={(e) => setCouponOffer(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-details">
                            <label>Coupon Valid Date</label>
                            <input
                              type="date"
                              onChange={(e) => setValidData(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-details">
                            <label>Coupon CODE</label>
                            <input
                              type="text"
                              placeholder="Ex: cashdhundo100"
                              onChange={(e) => setCode(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-8">
                          <div className="input-details">
                            <label>Coupon Terms & Conditions</label>
                            <input
                              type="text"
                              onChange={(e) => setTandC(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4 button_height d-flex justify-content-start align-items-center">
                          <button type="button" onClick={termsHandler}>
                            Add T&C
                          </button>
                        </div>
                        <div className="col-12 feature_and_other">
                          <ul>
                            {termsC?.map((val, ind) => {
                              return (
                                <li key={ind}>
                                  {val}
                                  <span
                                    type="button"
                                    title={`Delete ${val}`}
                                    onClick={() => termsDeleteHandler(ind)}
                                  >
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="col-8">
                          <div className="input-details">
                            <label>Meta Keyword</label>
                            <input
                              type="text"
                              onChange={(e) => setKeyword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4 button_height d-flex justify-content-start align-items-center">
                          <button type="button" onClick={keywordHandler}>
                            Add Keyword
                          </button>
                        </div>
                        <div className="col-12 feature_and_other">
                          <ul>
                            {keywordData?.map((val, ind) => {
                              return (
                                <li key={ind}>
                                  {val}
                                  <span
                                    type="button"
                                    title={`Delete ${val}`}
                                    onClick={() => keywordDelete(ind)}
                                  >
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="col-12">
                          <div className="input-details">
                            <label>Campaign Link</label>
                            <p>
                              <span className="text-danger fw-bold">Note:</span>{" "}
                              Ex: Tracker link
                              https://mediaxpedia.gotrackier.com/click?campaign_id=24780&pub_id=1603
                            </p>
                            <input
                              type="text"
                              onChange={(e) => setLink(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="input-details">
                            <label>Meta Description</label>
                            <textarea
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-3 mt-4 mb-4">
                          {loading ? (
                            <button
                              type="submit"
                              className="global_form_button"
                              disabled
                            >
                              Please wait...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="global_form_button"
                            >
                              Create Coupon
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCoupon;
