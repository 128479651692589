import React, { useState } from "react";
import HeaderBtn from "../../Components/HeaderBtn";
import Heading from "../../Components/Heading";
import { Link } from "react-router-dom";
import {
  FiCheckCircle,
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiTrash2,
  FiXCircle,
} from "react-icons/fi";
import { useAllUserDataQuery } from "../../App/Slice/authSlice";
import Nav from "../../Components/Nav";

function AllUsers() {
  const { data, isLoading } = useAllUserDataQuery();
  const user = data?.users;
  const totaluser = data?.users_length;

  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = user?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el.sub_id.toLowerCase().includes(search);
    }
  });

  return (
    <div className="container-fluid Dashboard-Round">
      <div className="row">
        <Nav />
        <div className="col-lg-10">
          <HeaderBtn urls="/" addUrls="/add-admin-user" addTitle="Add User" />
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <Heading title="All Users" length={totaluser} />
              <div className="user_search">
                <form>
                  <div className="input-deatils">
                    <input
                      type="search"
                      placeholder="search sub id "
                      onChange={inputHandler}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col_out_side">
            <table className="table table-striped">
              <thead className="table_head">
                <tr>
                  <th scope="col" title="Create User Date">
                    CUD
                  </th>
                  <th scope="col">UID</th>
                  <th scope="col">Sub ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Access</th>
                  <th scope="col">Profile</th>
                  <th scope="col">Others</th>
                </tr>
              </thead>
              {
                isLoading ? <p>Fetching data...</p> :
              <tbody>
                {filteredData?.map((val, ind) => {
                  const {
                    _id,
                    username,
                    sub_id,
                    email,
                    phone,
                    role,
                    createdAt,
                    email_verified,
                  } = val;
                  return (
                    <tr key={ind} className="table_body_row">
                      <th scope="row">{createdAt?.slice(0, 10)}</th>
                      <td>{_id}</td>
                      <td>
                        <Link to="">{sub_id}</Link>
                      </td>
                      <td>{username}</td>
                      <td>
                        {email}{" "}
                        {email_verified === "true" ? (
                          <span className="text-success" title="verify email">
                            <FiCheckCircle />
                          </span>
                        ) : (
                          <span
                            className="text-danger"
                            title="not verify email"
                          >
                            <FiXCircle />
                          </span>
                        )}
                      </td>
                      <td>{phone}</td>
                      <td>
                        {role === "admin" ? (
                          <span className="admin_status" type="button">
                            {role}
                          </span>
                        ) : (
                          <span className="normal_status" type="button">
                            {role}
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <Link
                          to=""
                          type="button"
                          className="btn btn-secondary"
                          target="_blank"
                        >
                          Check
                        </Link>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            className="btn btn-dark btn-sm dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FiMoreVertical />
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to="" title={`View title`}>
                                <FiEye />
                              </Link>
                            </li>
                            <li>
                              <Link to={`/update-deals/`} title={`Edit title`}>
                                <FiEdit />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                title={`Delete title`}
                                // onClick={() => deleteHandler(_id)}
                              >
                                <FiTrash2 />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllUsers;
