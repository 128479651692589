import React, { useEffect, useState } from "react";
import {
  useGetByIdCategoryQuery,
  useUpdatedCategoryMutation,
} from "../../App/Slice/categorySlice";
import { toast } from "react-toastify";

function UpdateCategory(udpateID) {
  const ids = udpateID?.udpateID;

  const [name, setName] = useState();
  const [status, setStatus] = useState();

  const { data: getCategory, isLoading: getIdLoading } =
    useGetByIdCategoryQuery(ids);
  const cateID = getCategory?.category;

  useEffect(() => {
    const { name, status } = cateID || {};
    setName(name);
    setStatus(status);
  }, [cateID]);

  const [updateCategory, isLoading] = useUpdatedCategoryMutation();
  const loading = isLoading?.isLoading;

  const updatedCategoryHandler = async (e) => {
    e.preventDefault();

    const data = { name: name, status: status };

    const result = await updateCategory({ ids, data });
    const message = await result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleUpdate"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleUpdateLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleUpdateLabel">
                Update {name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {getIdLoading ? (
              <p>Fetching data...</p>
            ) : (
              <div className="modal-body">
                <form onSubmit={updatedCategoryHandler}>
                  <div className="row w-85 mx-auto">
                    <div className="col-12">
                      <div className="input-details">
                        <label>Status</label>
                        <select onChange={(e) => setStatus(e.target.value)}>
                          <option value="select status">{status}</option>
                          <option value="success">success</option>
                          <option value="pending">pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-details">
                        <label>Name</label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 mt-4 mb-4">
                      {loading ? (
                        <button
                          type="submit"
                          className="global_form_button"
                          disabled
                        >
                          Please wait...
                        </button>
                      ) : (
                        <button type="submit" className="global_form_button">
                          Update Category
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateCategory;
