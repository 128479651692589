import React, { useEffect } from "react";
import Nav from "../../Components/Nav";
import { useGetPostbackQuery } from "../../App/Slice/postbackSlice";
import { AuthCheck } from "../../Secure/Helper";
import { Link } from "react-router-dom";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import UploadCSV from "./UploadCSV";

function ClickPostbackURL() {
  const { data, isLoading } = useGetPostbackQuery();
  const postback_click = data?.transaction;

  useEffect(() => {
    AuthCheck();
  }, []);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <div className="update_add_button d-flex justify-content-between mb-3 mt-3">
              <Link to="/" type="button" className="global_btn">
                <FiArrowLeft /> <span>Back</span>
              </Link>
              <a
                data-bs-toggle="modal"
                href="#exampleModalToggleUpload"
                role="button"
                className="global_btn"
              >
                <FiPlus /> <span>Upload CSV</span>
              </a>
            </div>
            <div className="col_out_side">
              <table className="table table-striped">
                <thead className="table_head">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Sub ID</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Campaign</th>
                    <th scope="col">Cashback</th>
                    <th scope="col">Reference</th>
                    <th scope="col">ZIP OID</th>
                    <th scope="col">Others</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <tbody>
                    {postback_click?.map((val, ind) => {
                      const {
                        sub_id,
                        order_id,
                        sale_amount,
                        currency,
                        campaign_name,
                        cd_order_id,
                        refernce_link,
                        cashback,
                        createdAt,
                      } = val;

                      const cashbackFilter = cashback?.slice(0, 3);
                      const cashbackO = cashback?.slice(3);
                      return (
                        <tr key={ind} className="table_body_row">
                          <th scope="row">{createdAt?.slice(0, 10)}</th>
                          <td>
                            <Link to="">{sub_id}</Link>
                          </td>
                          <td>{order_id}</td>
                          <td>{sale_amount}</td>
                          <td>{currency}</td>
                          <td>{campaign_name}</td>
                          <td>
                            {cashbackFilter === "ZF "
                              ? `Falt ${cashbackO}`
                              : cashbackFilter === "FP "
                              ? `Flat ${cashbackO}%`
                              : cashbackFilter === "ZU "
                              ? `Upto ${cashbackO}`
                              : cashbackFilter === "UP "
                              ? `Upto ${cashbackO}%`
                              : null}
                          </td>
                          <td>{refernce_link}</td>
                          <td>{cd_order_id}</td>
                          <td>
                            <button type="button" className="btn-danger">
                              DELETE
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <UploadCSV />
    </>
  );
}

export default ClickPostbackURL;
