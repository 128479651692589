import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const Deals = createApi({
  reducerPath: "Deals",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getDeals: builder.query({
      query: () => ({
        url: "/deals",
        method: "GET",
      }),
    }),

    getByIdDeals: builder.query({
      query: (id) => {
        return {
          url: `/deals/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteDeals: builder.mutation({
      query: (id) => {
        return {
          url: `/deals/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedDeals: builder.mutation({
      query: (body) => {
        return {
          url: `/deals/${body.ids}`,
          method: "PUT",
          body: body.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createDeals: builder.mutation({
      query: (q) => {
        // console.log("query : ", q);
        return {
          url: "/deals",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetByIdDealsQuery,
  useGetDealsQuery,
  useDeleteDealsMutation,
  useCreateDealsMutation,
  useUpdatedDealsMutation,
} = Deals;
