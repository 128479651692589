import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCreateMediaMutation } from "../../App/Slice/mediaSlice";

function AddMedia() {
  const [image, setImage] = useState();
  //   console.log();

  const [createCategory, isLoading] = useCreateMediaMutation();
  const loading = isLoading?.isLoading;

  const createMediaHandler = async (e) => {
    e.preventDefault();
    //formData
    const formData = new FormData();
    formData.append("file", image);

    const result = await createCategory(formData);
    const message = result?.data;
    if (message?.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleMedia"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleMediaLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleMediaLabel">
                Add New Media
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={createMediaHandler}>
                <div className="row w-85 mx-auto">
                  <div className="col-12">
                    <div className="input-details">
                      <label>Image</label>
                      <p>
                        <span className="text-danger fw-bold">Note:</span> Media
                        should be upload under 4.5MB || Image Name Ex: Number &
                        Alphabate without space
                      </p>
                      <input
                        type="file"
                        placeholder="Enter Url"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-4 mb-4">
                    {loading ? (
                      <button
                        type="submit"
                        className="global_form_button"
                        disabled
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button type="submit" className="global_form_button">
                        Add Media
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMedia;
