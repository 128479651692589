import React, { useEffect, useState } from "react";
import HeaderBtn from "../../Components/HeaderBtn";
import Heading from "../../Components/Heading";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import Nav from "../../Components/Nav";
import { useGetCashbackQuery } from "../../App/Slice/cashbackSlice";
import { AuthCheck } from "../../Secure/Helper";

function AllCashback() {
  const { data, isLoading } = useGetCashbackQuery();
  const cashbackHistory = data?.cashback_history;
  const totalcashback = data?.cashback_history_length;

  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = cashbackHistory?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.sub_id?.toLowerCase()?.includes(search);
    }
  });

  useEffect(() => {
    AuthCheck();
  }, []);

  return (
    <div className="container-fluid Dashboard-Round">
      <div className="row">
        <Nav />
        <div className="col-lg-10">
          <HeaderBtn urls="/" addUrls="/add-admin-user" addTitle="Add User" />
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <Heading title="All Missing Report" length={totalcashback} />
              <div className="user_search">
                <form>
                  <div className="input-deatils">
                    <input
                      type="search"
                      placeholder="search sub id "
                      onChange={inputHandler}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col_out_side">
            <table className="table table-striped">
              <thead className="table_head">
                <tr>
                  <th scope="col" title="Create User Date">
                    CUD
                  </th>
                  <th scope="col">Sub ID</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Store</th>
                  <th scope="col">Trans Date</th>
                  <th scope="col">PA</th>
                  <th scope="col">Cashback</th>
                  <th scope="col">Status</th>
                  <th scope="col">Others</th>
                </tr>
              </thead>
              {isLoading ? (
                <p>Fetching data...</p>
              ) : (
                <tbody>
                  {filteredData?.map((val, ind) => {
                    const {
                      _id,
                      createdAt,
                      status,
                      transaction_id,
                      transaction_date,
                      client,
                      cashback,
                      sale_amount,
                      sub_id,
                    } = val;
                    const date = transaction_date?.slice(0, 16);
                    return (
                      <tr key={ind} className="table_body_row">
                        <th scope="row">{createdAt?.slice(0, 10)}</th>
                        <td>{sub_id}</td>
                        <td>{transaction_id}</td>
                        <td>{client}</td>
                        <td>{date}</td>
                        <td>{sale_amount}</td>
                        <td>{cashback}%</td>
                        <td>
                          {status === "approve" ? (
                            <span className="success_status" type="button">
                              {status}
                            </span>
                          ) : status === "pending" ? (
                            <span className="pending_status" type="button">
                              {status}
                            </span>
                          ) : (
                            <span className="failed_status" type="button">
                              {status}
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-dark btn-sm dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FiMoreVertical />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link to="" title={`View title`}>
                                  <FiEye />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/update-cashback/${_id}`}
                                  title={`Edit title`}
                                >
                                  <FiEdit />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  title={`Delete title`}
                                  // onClick={() => deleteHandler(_id)}
                                >
                                  <FiTrash2 />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCashback;
