import React from "react";
import Nav from "../../Components/Nav";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import Heading from "../../Components/Heading";
import { toast } from "react-toastify";
import {
  useDeleteCareerMutation,
  useGetCareerQuery,
} from "../../App/Slice/careerSlice";

function Career() {
  const { data, isLoading } = useGetCareerQuery();
  const career = data?.career;

  const [deleteCareer] = useDeleteCareerMutation();

  const loc = "https://api.cashdhundo.com/public/uploads";

  const deleteHandler = async (id) => {
    const result = await deleteCareer(id);
    const res = result?.data;
    if (res.status === "failed") {
      toast.error("Try Again", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(res.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/career";
      }, 1000);
    }
  };
  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <Heading title="All Contact" />
            <div className="col_out_side">
              <table className="table table-striped">
                <thead className="table_head">
                  <tr>
                    <th scope="col">CNU</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Specialization</th>
                    <th scope="col">Description</th>
                    <th scope="col">Resume</th>
                    <th scope="col">Others</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <tbody>
                    {career?.map((val, ind) => {
                      const {
                        _id,
                        name,
                        email,
                        phone,
                        designation,
                        specialization,
                        resume,
                        description,
                        createdAt,
                      } = val;
                      return (
                        <tr key={ind} className="table_body_row">
                          <th scope="row">{createdAt?.slice(0, 10)}</th>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>{phone}</td>
                          <td>{designation}</td>
                          <td>{specialization}</td>
                          <td>{description}</td>
                          <td>
                            <Link
                              to={`${loc}/${resume}`}
                              download={resume}
                              type="button"
                              className="btn btn-dark"
                              title={resume}
                              target="_blank"
                            >
                              View
                            </Link>
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-dark btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FiMoreVertical />
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <Link to="">
                                    <FiEye />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`/update-coupons/id`}
                                    //   title={`Edit ${title}`}
                                  >
                                    <FiEdit />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    title={`Delete ${name}`}
                                    onClick={() => deleteHandler(_id)}
                                  >
                                    <FiTrash2 />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
