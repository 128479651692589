import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateRegisterUserMutation } from "../App/Slice/authSlice";

function SignUp() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setConformPassword] = useState();

  const nevigate = useNavigate();
  const [signUpCreate, isLoading] = useCreateRegisterUserMutation();
  const loading = isLoading?.isLoading

  // console.log("token :", GETTOKEN());

  const RegisterHandler = (e) => {
    e.preventDefault();
    
  };

  return (
    <>
      <div className="container-fluid p-0 form-bg-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-12 p-0">
            <div className="login-form">
              <form onSubmit={RegisterHandler}>
              <div className="input-fields text-center mb-2">
                  <img src="../../assets/image/logo.png" alt="cashdhundo" />
                </div>
                <div className="input-fields">
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Media Xpedia"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Enter E-mail</label>
                  <input
                    type="text"
                    placeholder="abc@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Access</label>
                  <select>
                    <option value="select Accesss">select Accesss</option>
                    <option value="admin">admin</option>
                    <option value="CSDC">CSDC</option>
                    <option value="Blogger">Blogger</option>
                    <option value="Accountant">Accountant</option>
                  </select>
                </div>
                <div className="input-fields">
                  <label>Password</label>
                  <input
                    type="text"
                    placeholder="********"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Conform Password</label>
                  <input
                    type="password"
                    placeholder="********"
                    onChange={(e) => setConformPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields submit-button mb-0">
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Register New User"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
