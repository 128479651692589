import React from "react";
import Nav from "../../Components/Nav";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import Heading from "../../Components/Heading";
import { toast } from "react-toastify";
import {
  useDeletePartnerMutation,
  useGetPartnerQuery,
} from "../../App/Slice/partnerSlice";

function Partner() {
  const { data, isLoading } = useGetPartnerQuery();
  const partner = data?.partner;

  const [deletePartner] = useDeletePartnerMutation();

  const deleteHandler = async (id) => {
    const result = await deletePartner(id);
    const res = result?.data;
    if (res.status === "failed") {
      toast.error("Try Again", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(res.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/partner";
      }, 1000);
    }
  };
  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <Heading title="All Join for Partnership" />
            <div className="col_out_side">
              <table className="table table-striped">
                <thead className="table_head">
                  <tr>
                    <th scope="col">CNU</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Legal Name</th>
                    <th scope="col">Website</th>
                    <th scope="col">Address</th>
                    <th scope="col">Message</th>
                    <th scope="col">Others</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <tbody>
                    {partner?.map((val, ind) => {
                      const {
                        _id,
                        name,
                        email,
                        website,
                        message,
                        createdAt,
                        companyName,
                        legalName,
                        address,
                      } = val;
                      return (
                        <tr key={ind} className="table_body_row">
                          <th scope="row">{createdAt?.slice(0, 10)}</th>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>{companyName}</td>
                          <td>{legalName}</td>
                          <td>
                            <Link to={website} target="_blank">
                              View Site
                            </Link>
                          </td>
                          <td>{address}</td>
                          <td>{message}</td>
                          <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-dark btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FiMoreVertical />
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <Link to="">
                                    <FiEye />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    //   title={`Edit ${title}`}
                                  >
                                    <FiEdit />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    title={`Delete ${name}`}
                                    onClick={() => deleteHandler(_id)}
                                  >
                                    <FiTrash2 />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partner;
