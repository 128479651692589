import React from "react";

function Heading(props) {
  return (
    <>
      <div className="row">
        <div className="col-12 heading_title d-flex justify-content-start align-items-center">
          <h6>{props.title}</h6>
          <h6>{props.length}</h6>
        </div>
      </div>
    </>
  );
}

export default Heading;
