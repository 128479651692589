import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const subcategory = createApi({
  reducerPath: "subcategory",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getsubCategory: builder.query({
      query: () => ({
        url: "/sub-category",
        method: "GET",
      }),
    }),

    getByIdsubCategory: builder.query({
      query: (id) => {
        return {
          url: `/sub-category/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    searchsubCategory: builder.query({
      query: (key) => {
        return {
          url: `/sub-category/search/${key}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deletesubCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/sub-category/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedsubCategory: builder.mutation({
      query: (id) => {
        // console.log("ids :", id);
        return {
          url: `/sub-category/${id.ids}`,
          method: "PUT",
          body: id.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createsubCategory: builder.mutation({
      query: (q) => {
        return {
          url: "/sub-category",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetsubCategoryQuery,
  useGetByIdsubCategoryQuery,
  useDeletesubCategoryMutation,
  useCreatesubCategoryMutation,
  useUpdatedsubCategoryMutation,
  useSearchsubCategoryQuery,
} = subcategory;
