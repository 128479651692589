import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const cashback = createApi({
  reducerPath: "cashback",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCashback: builder.query({
      query: () => ({
        url: "/cashback-history/admin",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    getByIdCashback: builder.query({
      query: (id) => {
        // console.log("id", id);
        return {
          url: `/cashback-history/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    searchCashback: builder.query({
      query: (key) => {
        console.log("key", key);
        return {
          url: `/cashback-history/search/${key}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteCashback: builder.mutation({
      query: (id) => {
        return {
          url: `/cashback-history/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedCashback: builder.mutation({
      query: (data) => {
        // console.log("update result :", data);
        return {
          url: `/cashback-history/${data.id}`,
          method: "PUT",
          body: data.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createCashback: builder.mutation({
      query: (q) => {
        // console.log("query : ", q);
        return {
          url: "/cashback-history",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
    
  }),
});

export const {
  useGetCashbackQuery,
  useCreateCashbackMutation,
  useDeleteCashbackMutation,
  useUpdatedCashbackMutation,
  useGetByIdCashbackQuery,
} = cashback;
