import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const withdrawl = createApi({
  reducerPath: "withdrawl",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getWithdrawl: builder.query({
      query: () => ({
        url: "/_10302023_wi_moon",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    getByIdWithdrawl: builder.query({
      query: (id) => {
        return {
          url: `/_10302023_wi_moon/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedWithdrawl: builder.mutation({
      query: (da) => {
        return {
          url: `/_10302023_wi_moon/${da.id}`,
          method: "PUT",
          body: da.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteWithdrawl: builder.mutation({
      query: (id) => {
        return {
          url: `/_10302023_wi_moon/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createWithdrawl: builder.mutation({
      query: (q) => {
        // console.log("query : ", q);
        return {
          url: "/_10302023_wi_moon",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetWithdrawlQuery,
  useGetByIdWithdrawlQuery,
  useUpdatedWithdrawlMutation,
} = withdrawl;
