import React, { useState } from "react";
import { useGetCategoryQuery } from "../../App/Slice/categorySlice";
import { toast } from "react-toastify";
import { useCreatesubCategoryMutation } from "../../App/Slice/subCategorySlice";

function AddSubCategory() {
  const [category, setCategory] = useState();
  const [name, setName] = useState();

  const { data: Pcategory } = useGetCategoryQuery();
  const mCate = Pcategory?.category;

  const [createCategory, isLoading] = useCreatesubCategoryMutation();
  const loading = isLoading?.isLoading;

  const createCategoryHandler = async (e) => {
    e.preventDefault();
    const data = { category: category, name: name };

    const result = await createCategory(data);
    const message = await result?.data;
    if (message?.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Add New Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={createCategoryHandler}>
                <div className="row w-85 mx-auto">
                  <div className="col-12">
                    <div className="input-details">
                      <label>Parent Category</label>
                      <select onChange={(e) => setCategory(e.target.value)}>
                        <option value="select category">select category</option>
                        {mCate?.map((val, ind) => {
                          return <option value={val._id}>{val.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="input-details">
                      <label>Name</label>
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-4 mb-4">
                    {loading ? (
                      <button
                        type="submit"
                        className="global_form_button"
                        disabled
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button type="submit" className="global_form_button">
                        Create Category
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSubCategory;
