import React, { useEffect, useState } from "react";
import HeaderBtn from "../../Components/HeaderBtn";
import Nav from "../../Components/Nav";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  useDeleteDealsMutation,
  useGetDealsQuery,
} from "../../App/Slice/dealsSlice";
import Heading from "../../Components/Heading";
import { AuthCheck } from "../../Secure/Helper";

function AllDeals() {
  const { data, isLoading } = useGetDealsQuery();
  const deals = data?.deal;

  const product_list = deals?.filter((ele) => ele.convert_deal_Product.includes("product"))
  const deals_list = deals?.filter((ele) => ele.convert_deal_Product.includes("deal"))

  const [deleteDeal] = useDeleteDealsMutation();

  const deleteHandler = async (id) => {
    const result = await deleteDeal(id);
    const message = result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = deals?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.title?.toLowerCase()?.includes(search);
    }
  });

  useEffect(() => {
    AuthCheck();
  }, []);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <HeaderBtn
              urls="/"
              addUrls="/add-deals"
              addTitle="Add New Deal & Product"
            />
            <div className="d-flex justify-content-start">
              <Heading title={`All Deals ${deals_list?.length} & Product ${product_list?.length}`} />
              <div className="user_search">
                <form>
                  <div className="input-deatils">
                    <input
                      type="search"
                      placeholder="search title "
                      onChange={inputHandler}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col_out_side">
              <table className="table table-striped">
                <thead className="table_head">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">image</th>
                    <th scope="col">Campaign</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Price</th>
                    <th scope="col">Product Link</th>
                    <th scope="col">Status</th>
                    <th scope="col">Others</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <tbody>
                    {filteredData?.map((val, ind) => {
                      const {
                        _id,
                        category,
                        client,
                        image,
                        link,
                        mrp_rate,
                        real_rate,
                        title,
                        status,
                        convert_deal_Product,
                        createdAt,
                      } = val;
                      return (
                        <tr key={ind} className="table_body_row">
                          <th scope="row">{createdAt?.slice(0, 10)}</th>
                          <td>
                            {convert_deal_Product === "deal" ? (
                              <p>Deal</p>
                            ) : (
                              <img src={image} alt={title} />
                            )}
                          </td>
                          <td>{client?.name}</td>
                          <td>
                            <Link to="">{title}</Link>
                          </td>
                          <td>{category?.name}</td>
                          <td>
                            {convert_deal_Product === "deal" ? (
                              <p>Null</p>
                            ) : (
                              <>
                                <s>₹{mrp_rate}</s> | ₹{real_rate}
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            <Link
                              to={link}
                              type="button"
                              className="btn btn-primary"
                              target="_blank"
                            >
                              Link
                            </Link>
                          </td>
                          <td>
                            {status === "success" ? (
                              <span className="success_status" type="button">
                                {status}
                              </span>
                            ) : (
                              <span className="failed_status" type="button">
                                {status}
                              </span>
                            )}
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-dark btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FiMoreVertical />
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <Link to="" title={`View ${title}`}>
                                    <FiEye />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`/update-deals/${_id}`}
                                    title={`Edit ${title}`}
                                  >
                                    <FiEdit />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    title={`Delete ${title}`}
                                    onClick={() => deleteHandler(_id)}
                                  >
                                    <FiTrash2 />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDeals;
