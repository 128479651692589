import React, { useState } from "react";
import HeaderBtn from "../../Components/HeaderBtn";
import Nav from "../../Components/Nav";
import {
  useDeleteCampaignMutation,
  useGetCampaignQuery,
} from "../../App/Slice/campaignSlice";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import { IoMdTrendingUp } from "react-icons/io";
import Heading from "../../Components/Heading";

function AllStore() {
  const { data, isLoading } = useGetCampaignQuery();
  const campaign = data?.client;

  const [deleteCampaign] = useDeleteCampaignMutation();

  const deleteHandler = async (id) => {
    const result = await deleteCampaign(id);
    const message = result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };


  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = campaign?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.name?.toLowerCase()?.includes(search);
    }
  });

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <HeaderBtn
              urls="/"
              addUrls="/add-campaign"
              addTitle="Add New Campaign"
            />
            <div className="d-flex justify-content-start">
            <Heading title={`All Store ${campaign?.length}`} />
              <div className="user_search">
                <form>
                  <div className="input-deatils">
                    <input
                      type="search"
                      placeholder="search title "
                      onChange={inputHandler}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col_out_side">
              <table className="table table-striped">
                <thead className="table_head">
                  <tr>
                    <th scope="col-12">Date</th>
                    <th scope="col-12">Campaign</th>
                    <th scope="col-12">Cashback</th>
                    <th scope="col-12">Deep Link</th>
                    <th scope="col-12">Campaign Link</th>
                    <th scope="col-12">Status</th>
                    <th scope="col-12">Others</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <tbody>
                    {filteredData?.map((val, ind) => {
                      const {
                        _id,
                        deep_link,
                        link,
                        name,
                        status,
                        calc,
                        createdAt,
                        trending,
                      } = val;
                      const cashbackFilter = calc[0];
                      const cashbackO = calc[1];
                      return (
                        <tr key={ind} className="table_body_row">
                          <th scope="row">{createdAt?.slice(0, 10)}</th>
                          <td>
                            {trending === "true" ? (
                              <span style={{ color: "green" }}>
                                <IoMdTrendingUp />
                              </span>
                            ) : null}{" "}
                            <Link to="">{name}</Link>
                          </td>
                          {cashbackFilter === null ||
                          cashbackFilter === "Paused" ? (
                            <p className="bg-danger font-white">
                              Not Applicable
                            </p>
                          ) : (
                            <td>
                              {cashbackFilter === "ZF"
                                ? `Falt ${cashbackO} cashback`
                                : cashbackFilter === "FP"
                                ? `Flat ${cashbackO}% cashback`
                                : cashbackFilter === "ZU"
                                ? `Upto ${cashbackO} cashback`
                                : cashbackFilter === "UP"
                                ? `Upto ${cashbackO}% cashback`
                                : null}
                            </td>
                          )}
                          <td>{deep_link}</td>
                          <td className="text-center">
                            <Link
                              to={link}
                              type="button"
                              className="btn btn-primary"
                              target="_blank"
                            >
                              Link
                            </Link>
                          </td>
                          <td>
                            {status === "success" ? (
                              <span className="success_status" type="button">
                                {status}
                              </span>
                            ) : (
                              <span className="failed_status" type="button">
                                {status}
                              </span>
                            )}
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-dark btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FiMoreVertical />
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <Link to="" title={`View ${name}`}>
                                    <FiEye />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`/update-campaign/${_id}`}
                                    title={`Edit ${name}`}
                                  >
                                    <FiEdit />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    title={`Delete ${name}`}
                                    onClick={() => deleteHandler(_id)}
                                  >
                                    <FiTrash2 />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllStore;
