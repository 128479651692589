import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const contact = createApi({
  reducerPath: "contact",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getContact: builder.query({
      query: () => ({
        url: "/contact",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    getByIdContact: builder.query({
      query: (id) => {
        return {
          url: `/contact/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteContact: builder.mutation({
      query: (id) => {
        return {
          url: `/contact/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetContactQuery,
  useGetByIdContactQuery,
  useDeleteContactMutation,
} = contact;
