import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const coupon = createApi({
  reducerPath: "coupon",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCoupon: builder.query({
      query: () => ({
        url: "/coupons",
        method: "GET",
      }),
    }),

    getByIdCoupon: builder.query({
      query: (id) => {
        return {
          url: `/coupons/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteCoupon: builder.mutation({
      query: (id) => {
        return {
          url: `/coupons/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedCoupon: builder.mutation({
      query: (body) => {
        return {
          url: `/coupons/${body.ids}`,
          method: "PUT",
          body: body.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createCoupon: builder.mutation({
      query: (q) => {
        return {
          url: "/coupons",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCouponQuery,
  useGetByIdCouponQuery,
  useCreateCouponMutation,
  useUpdatedCouponMutation,
  useDeleteCouponMutation,
} = coupon;
