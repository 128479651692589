import React, { useEffect, useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { FiXCircle } from "react-icons/fi";
import { toast } from "react-toastify";
import { useGetsubCategoryQuery } from "../../App/Slice/subCategorySlice";
import { Editor } from "@tinymce/tinymce-react";
import {
  useGetByIdBlogQuery,
  useUpdatedBlogMutation,
} from "../../App/Slice/blogSlice";
import { useParams } from "react-router-dom";

function UpdatedBlog() {
  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [category, setCategory] = useState();
  const [keyword, setKeyword] = useState();
  const [description, setDescription] = useState();
  const [short_desc, setShortDesc] = useState();
  const [status, setStatus] = useState();
  const [trending, setTrending] = useState();

  const params = useParams();
  const ids = params.id;

  const { data: categoryData } = useGetsubCategoryQuery();
  const subcategory = categoryData?.sub_category;

  const [updatedBlog, isLoading] = useUpdatedBlogMutation();
  const loading = isLoading?.isLoading;

  const { data: blogById, isLoading: getIdLoading } = useGetByIdBlogQuery(ids);
  const idBlog = blogById?.blog;

  console.log("blog :", description);

  //keyword Arrary Add
  const [keywordData, setKeywordData] = useState([]);
  const keywordHandler = () => {
    const fe = keyword;
    const addKeyword = [...keywordData, fe];
    setKeywordData(addKeyword);
  };
  //keyword Array Delete
  const keywordDelete = (id) => {
    const deleteKeyword = keywordData.filter((ele, ind) => {
      return ind !== id;
    });
    setKeywordData(deleteKeyword);
  };

  const UpdatedPostHandler = async (e) => {
    e.preventDefault();
    const data = {
      title: title,
      image: image,
      category: category,
      description: description,
      short_desc: short_desc,
      keyword: keywordData,
      status: status,
      trending: trending,
    };
    const result = await updatedBlog({ ids, data });
    const message = result?.data;
    if (message?.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/blogs";
      }, 1000);
    }
  };

  useEffect(() => {
    const {
      title,
      status,
      trending,
      description,
      short_desc,
      image,
      keyword,
      category,
    } = idBlog || {};
    setTitle(title);
    setStatus(status);
    setTrending(trending);
    setDescription(description);
    setShortDesc(short_desc);
    setImage(image);
    setKeywordData(keyword);
    setCategory(category);
  }, [idBlog]);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <HeaderBtn urls="/blogs" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Add New Blog</h4>
                  </div>
                  {getIdLoading ? (
                    <p>Fetching data...</p>
                  ) : (
                    <div className="global_form_style">
                      <form onSubmit={UpdatedPostHandler}>
                        <div className="row w-85 mx-auto">
                          <div className="col-4">
                            <div className="input-details">
                              <label>Status</label>
                              <select
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option
                                  value="select category"
                                  disabled
                                  selected
                                >
                                  {status}
                                </option>
                                <option value="success">success</option>
                                <option value="pending">pending</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Trending</label>
                              <select
                                onChange={(e) => setTrending(e.target.value)}
                              >
                                <option
                                  value="select Trending"
                                  disabled
                                  selected
                                >
                                  {trending}
                                </option>
                                <option value="yes">yes</option>
                                <option value="no">no</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="input-details">
                              <label>Title</label>
                              <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Blog Image</label>
                              <input
                                type="text"
                                value={image}
                                onChange={(e) => setImage(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Category</label>
                              <select
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option
                                  value="select sub category"
                                  disabled
                                  selected
                                >
                                  {category?.name}
                                </option>
                                {subcategory?.map((val, ind) => {
                                  return (
                                    <option value={val._id}>{val.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="input-details">
                              <label>Short Description</label>
                              <input
                                type="text"
                                value={short_desc}
                                onChange={(e) => setShortDesc(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-8">
                            <div className="input-details">
                              <label>Meta Keyword</label>
                              <input
                                type="text"
                                onChange={(e) => setKeyword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-4 button_height d-flex justify-content-start align-items-center">
                            <button type="button" onClick={keywordHandler}>
                              Add Keyword
                            </button>
                          </div>
                          <div className="col-12 feature_and_other">
                            <ul>
                              {keywordData?.map((val, ind) => {
                                return (
                                  <li key={ind}>
                                    {val}
                                    <span
                                      type="button"
                                      title={`Delete ${val}`}
                                      onClick={() => keywordDelete(ind)}
                                    >
                                      <FiXCircle />
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="col-12">
                            <div className="input-details">
                              <label>Blog Description</label>
                              <Editor
                                // initialValue="write a blog..."
                                onEditorChange={(newText) =>
                                  setDescription(newText)
                                }
                                value={description}
                                init={{
                                  height: 660,
                                  width: "100%",
                                  plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | code | help",
                                  image_title: true,
                                  automatic_uploads: true,
                                  file_picker_types: "image",
                                  file_picker_callback: function (
                                    callback,
                                    value,
                                    meta
                                  ) {
                                    if (meta.filetype === "image") {
                                      const input =
                                        document.createElement("input");
                                      input.setAttribute("type", "file");
                                      input.setAttribute(
                                        "accept",
                                        "https://api.cashdhundo.com/public/uploads/*"
                                      );
                                      input.onchange = function () {
                                        const file = this.files[0];
                                        const reader = new FileReader();
                                        reader.onload = function () {
                                          const id =
                                            "blobid" + new Date().getTime();
                                          const blobCache =
                                            window.tinymce.activeEditor
                                              .editorUpload.blobCache;
                                          const base64 =
                                            reader.result.split(",")[1];
                                          const blobInfo = blobCache.create(
                                            id,
                                            file,
                                            base64
                                          );
                                          blobCache.add(blobInfo);
                                          callback(blobInfo.blobUri(), {
                                            title: file.name,
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      };
                                      input.click();
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-3 mt-4 mb-4">
                            {loading ? (
                              <button
                                type="submit"
                                className="global_form_button"
                                disabled
                              >
                                Please wait...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="global_form_button"
                              >
                                Updated Blog
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatedBlog;
