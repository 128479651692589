import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <>
      <div className="col-lg-2 p-0 side-navbar sticky-top">
        <div>
          <ul>
            <li className="nav-items">
              <Link className="nav-link" to="/">
                Dashboard
              </Link>
            </li>
            <li className="nav-items">
              <Link className="nav-link d-flex justify-content-between" to="#">
                User{" "}
                <span>
                  <FiChevronRight />
                </span>
              </Link>
              <ul className="sub_menu">
                <li className="nav-items">
                  <Link className="nav-link" to="/users">
                    Users
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="">
                    Earning History
                  </Link>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Payment
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="">
                        Success Payment
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="">
                        Reject Payment
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="">
                        Pending Payment
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Withdrawl Request
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="/withdrawl-money">
                        Withdrawl Request
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/success-withdrawl">
                        Approve Withdrawl
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/pending-withdrawl">
                        Pending Withdrawl
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/reject-withdrawl">
                        Reject Withdrawl
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/transaction-report">
                    Transactions
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-items">
              <Link className="nav-link d-flex justify-content-between" to="#">
                Reports{" "}
                <span>
                  <FiChevronRight />
                </span>
              </Link>
              <ul className="sub_menu">
                <li className="nav-items">
                  <Link className="nav-link" to="">
                    Click Report
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/missing-report">
                    Missing Report
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/transaction-report">
                    Transition Report
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-items">
              <Link className="nav-link d-flex justify-content-between" to="#">
                Create Campaigns{" "}
                <span>
                  <FiChevronRight />
                </span>
              </Link>
              <ul className="sub_menu">
                <li className="nav-items">
                  <Link className="nav-link" to="/banner">
                    Banner
                  </Link>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Category
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="/category">
                        Category
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/sub-category">
                        Sub Category
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Store
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="/all-campaign">
                        All Stores
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/add-campaign">
                        Create Store
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Deals
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="/all-deals">
                        All Deals
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/add-deals">
                        Create Deal
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Coupons
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="/all-coupons">
                        All Coupons
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="/add-coupons">
                        Create Coupon
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-items">
              <Link className="nav-link d-flex justify-content-between" to="#">
                Cashback Report
                <span>
                  <FiChevronRight />
                </span>
              </Link>
              <ul className="sub_menu">
                <li className="nav-items">
                  <Link
                    className="nav-link d-flex justify-content-between"
                    to="#"
                  >
                    Cashback
                    <span>
                      <FiChevronRight />
                    </span>
                  </Link>
                  <ul className="sub_menu">
                    <li className="nav-items">
                      <Link className="nav-link" to="/cashback-report">
                        All Cashback
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link className="nav-link" to="">
                        Create Cashback
                      </Link>
                    </li>
                    <li className="nav-items">
                      <Link
                        className="nav-link d-flex justify-content-between"
                        to="#"
                      >
                        Cashback Status
                        <span>
                          <FiChevronRight />
                        </span>
                      </Link>
                      <ul className="sub_menu">
                        <li className="nav-items">
                          <Link className="nav-link" to="/success-cashback">
                            Approve Cashback
                          </Link>
                        </li>
                        <li className="nav-items">
                          <Link className="nav-link" to="/pending-cashback">
                            Pending Cashback
                          </Link>
                        </li>
                        <li className="nav-items">
                          <Link className="nav-link" to="/reject-cashback">
                            Reject Cashback
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="">
                    Report
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-items">
              <Link className="nav-link d-flex justify-content-between" to="#">
                Write Blog{" "}
                <span>
                  <FiChevronRight />
                </span>
              </Link>
              <ul className="sub_menu">
                <li className="nav-items">
                  <Link className="nav-link" to="/blogs">
                    Blogs
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="">
                    Create New Blog
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-items">
              <Link className="nav-link" to="/admin-user">
                Admin User
              </Link>
            </li>
            <li className="nav-items">
              <Link className="nav-link" to="/upload-media">
                Upload Media
              </Link>
            </li>
            <li className="nav-items">
              <Link className="nav-link d-flex justify-content-between" to="#">
                Support{" "}
                <span>
                  <FiChevronRight />
                </span>
              </Link>
              <ul className="sub_menu">
                <li className="nav-items">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/career">
                    Career
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/support">
                    User Support
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-items">
              <Link className="nav-link" to="/partner">
                Join Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Nav;
