import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const postback = createApi({
  reducerPath: "postback",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getPostback: builder.query({
      query: () => ({
        url: "/transaction/user-report",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    uploadCSVFile: builder.mutation({
      query: (q) => {
        console.log("file uploaded : ", q);
        return{
          url: "/transaction/acquisition",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        }
      },
    }),
  }),
});

export const {
  useGetPostbackQuery,
  useCreatePostbackMutation,
  useUploadCSVFileMutation,
} = postback;
