import React, { useEffect } from "react";
import Heading from "../../Components/Heading";
import Nav from "../../Components/Nav";
import { Link } from "react-router-dom";
import { FiArrowLeft, FiCopy, FiPlus, FiTrash2 } from "react-icons/fi";
import AddMedia from "./AddMedia";
import {
  useDeleteMediaMutation,
  useGetMediaQuery,
} from "../../App/Slice/mediaSlice";
import { AuthCheck, FILE_URL } from "../../Secure/Helper";
import { toast } from "react-toastify";

function Media() {
  const { data, isLoading } = useGetMediaQuery();
  const media = data?.Media;

  const [deleteMedia] = useDeleteMediaMutation();

  const DeleteHandler = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (userConfirmed) {
      const result = await deleteMedia(id);
      const message = result?.data;
      if (message?.status === "failed") {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        });
      } else {
        toast.success(message?.message, {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    AuthCheck();
  }, []);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <div className="update_add_button d-flex justify-content-between mb-3 mt-3">
              <Link to="/" type="button" className="global_btn">
                <FiArrowLeft /> <span>Back</span>
              </Link>
              <a
                type="button"
                className="global_btn"
                data-bs-toggle="modal"
                href="#exampleModalToggleMedia"
                role="button"
              >
                <FiPlus /> <span>Add Media</span>
              </a>
            </div>
            <Heading title="All Media" />
            <div className="col_out_side mt-3">
              {isLoading ? (
                <p>Fetching data...</p>
              ) : (
                <div className="row">
                  {media?.map((val, ind) => {
                    const { _id, image } = val;
                    return (
                      <div key={ind} className="col-2">
                        <div className="media_image">
                          <img src={`${FILE_URL}/${image}`} alt="cashdhundo" />
                          <div className="overflow_style">
                            <span
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${FILE_URL}/${val.image}`
                                );
                                alert("copy url link");
                              }}
                            >
                              <FiCopy />
                            </span>
                            <span onClick={() => DeleteHandler(val._id)}>
                              <FiTrash2 />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddMedia />
    </>
  );
}

export default Media;
