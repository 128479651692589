import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const blog = createApi({
  reducerPath: "blog",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getBlog: builder.query({
      query: () => ({
        url: "/blog",
        method: "GET",
      }),
    }),

    getByIdBlog: builder.query({
      query: (id) => {
        return {
          url: `/blog/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    searchBlog: builder.query({
      query: (key) => {
        console.log("key", key);
        return {
          url: `/blog/search/${key}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteBlog: builder.mutation({
      query: (id) => {
        return {
          url: `/blog/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedBlog: builder.mutation({
      query: (id) => {
        return {
          url: `/blog/${id.ids}`,
          method: "PUT",
          body: id.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createBlog: builder.mutation({
      query: (q) => {
        console.log("query : ", q);
        return {
          url: "/blog",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetBlogQuery,
  useCreateBlogMutation,
  useUpdatedBlogMutation,
  useGetByIdBlogQuery,
  useDeleteBlogMutation
} = blog;
