import React from "react";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

function HeaderBtn(props) {
  return (
    <>
      <div className="update_add_button d-flex justify-content-between mb-3 mt-3">
        {!props.urls ? (
          " "
        ) : (
          <Link to={props.urls} type="button" className="global_btn">
            <FiArrowLeft /> <span>Back</span>
          </Link>
        )}
        {!props.addTitle ? (
          " "
        ) : (
          <Link to={props.addUrls} type="button" className="global_btn">
            <FiPlus /> <span>{props.addTitle}</span>
          </Link>
        )}
      </div>
    </>
  );
}

export default HeaderBtn;
