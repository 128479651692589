export const URL_LINK = "https://api.cashdhundo.com/api";
export const FILE_URL = "https://api.cashdhundo.com/public/uploads";

// export const URL_LINK = "http://localhost:8006/api";
// export const FILE_URL = "http://localhost:8006/public/uploads";

// export const user = JSON.parse(localStorage.getItem("admin-user"));

export function GETTOKEN() {
  let user = JSON.parse(localStorage.getItem("admin-user"));
  if (user) {
    return user.token;
  } else {
    return "73j93js857fh589djsjaksj384940DJ34849Djjd";
  }
}

const storage = JSON.parse(localStorage.getItem("admin-user"));
export const Auth_user = storage?.role;

export const AuthCheck = async () => {
  const isFetching = await fetch(`${URL_LINK}/user/all-users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${GETTOKEN()}`,
    },
  });
  const response = await isFetching.json();
  const result = response?.status;
  console.log("result", result);
  if (result !== "failed") {
    return;
  } else {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }
};
