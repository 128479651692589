import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCreateBannerMutation } from "../../App/Slice/bannerSlice";
import { useGetCampaignQuery } from "../../App/Slice/campaignSlice";

function AddBanner() {
  const [client, setClient] = useState();
  const [image, setImage] = useState();
  const [link, setLink] = useState();

  const { data } = useGetCampaignQuery();
  const cli = data?.client;

  const [createCategory, isLoading] = useCreateBannerMutation();
  const loading = isLoading?.isLoading;

  const createCategoryHandler = async (e) => {
    e.preventDefault();
    const data = { client: client, link: link, image: image };

    const result = await createCategory(data);
    const message = result?.data;
    if (message?.status === "failed") {
      toast.error(result?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Add New Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={createCategoryHandler}>
                <div className="row w-85 mx-auto">
                  {image ? (
                    <div className="col-12">
                      <div className="category_image">
                        <img src={image} alt="cashdhundo" />
                      </div>
                    </div>
                  ) : (
                    " "
                  )}
                  <div className="col-12">
                    <div className="input-details">
                      <label>Select Client</label>
                      <select onClick={(e) => setClient(e.target.value)}>
                        <option value="Select Client" disabled selected>
                          Select Client
                        </option>
                        {cli?.map((val, ind) => {
                          return (
                            <option key={ind} value={val?._id}>
                              {val?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-details">
                      <label>Image</label>
                      <input
                        type="text"
                        placeholder="Enter Url"
                        onChange={(e) => setImage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-details">
                      <label>Link</label>
                      <input
                        type="text"
                        placeholder="Enter Url"
                        onChange={(e) => setLink(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-4 mb-4">
                    {loading ? (
                      <button
                        type="submit"
                        className="global_form_button"
                        disabled
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button type="submit" className="global_form_button">
                        Create Category
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBanner;
