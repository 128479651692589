import React, { useEffect, useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { FiXCircle } from "react-icons/fi";
import { useGetCampaignQuery } from "../../App/Slice/campaignSlice";
import { toast } from "react-toastify";
import {
  useGetByIdDealsQuery,
  useUpdatedDealsMutation,
} from "../../App/Slice/dealsSlice";
import { useGetsubCategoryQuery } from "../../App/Slice/subCategorySlice";
import { useParams } from "react-router-dom";

function UpdateDeals() {
  const params = useParams();
  const ids = params.id;

  const { data, isLoading: getIdLoading } = useGetByIdDealsQuery(ids);
  const singleDeals = data?.deal;

  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [mrp, setMRP] = useState();
  const [real, setREAL] = useState();
  const [client, setClient] = useState();
  const [category, setCategory] = useState();
  const [feature, setFeature] = useState();
  const [keyword, setKeyword] = useState();
  const [description, setDescription] = useState();
  const [link, setLink] = useState();
  const [status, setStatus] = useState();
  const [convert_deal_Product, setConvertDealProduct] = useState();
  // console.log("category", category);

  //get campaign
  const { data: clientData } = useGetCampaignQuery();
  const campaigns = clientData?.client;

  const { data: SubCat } = useGetsubCategoryQuery();
  const sub_category = SubCat?.sub_category;

  //update deals
  const [updatedDeals, isLoading] = useUpdatedDealsMutation();
  const loading = isLoading?.isLoading;

  //feature Arrary Add
  const [featureData, setFeatureData] = useState([]);
  const featureHandler = () => {
    const fe = feature;
    const addFeature = [...featureData, fe];
    setFeatureData(addFeature);
  };
  //feature Array Delete
  const featureDelete = (id) => {
    const deleteFeature = featureData.filter((ele, ind) => {
      return ind !== id;
    });
    setFeatureData(deleteFeature);
  };

  //keyword Arrary Add
  const [keywordData, setKeywordData] = useState([]);
  const keywordHandler = () => {
    const fe = keyword;
    const addKeyword = [...keywordData, fe];
    setKeywordData(addKeyword);
  };
  //keyword Array Delete
  const keywordDelete = (id) => {
    const deleteKeyword = keywordData.filter((ele, ind) => {
      return ind !== id;
    });
    setKeywordData(deleteKeyword);
  };

  const createPostHandler = async (e) => {
    e.preventDefault();
    const data = {
      title: title,
      image: image,
      client: client,
      category: category,
      mrp_rate: mrp,
      real_rate: real,
      feature: featureData,
      description: description,
      Keyword: keywordData,
      link: link,
      status: status,
      convert_deal_Product: convert_deal_Product,
    };
    const result = await updatedDeals({ ids, data });
    const message = result?.data;
    if (message?.status === "failed") {
      toast.error(result?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/all-deals";
      }, 1000);
    }
  };

  useEffect(() => {
    const {
      title,
      image,
      client,
      category,
      mrp_rate,
      real_rate,
      feature,
      description,
      keyword,
      convert_deal_Product,
      status,
      link,
    } = singleDeals || {};
    setTitle(title);
    setImage(image);
    setMRP(mrp_rate);
    setREAL(real_rate);
    setClient(client);
    setCategory(category);
    setFeatureData(feature);
    setDescription(description);
    setKeywordData(keyword);
    setLink(link);
    setStatus(status);
    setConvertDealProduct(convert_deal_Product);
  }, [singleDeals, params]);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <HeaderBtn urls="/all-deals" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Add New Deal</h4>
                  </div>
                  {getIdLoading ? (
                    <p>Fetching data...</p>
                  ) : (
                    <div className="global_form_style">
                      <form onSubmit={createPostHandler}>
                        <div className="row w-85 mx-auto">
                          <div className="col-4">
                            <div className="input-details">
                              <label>Deal Status</label>
                              <select
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="select Status" disabled selected>
                                  {status}
                                </option>
                                <option value="success">success</option>
                                <option value="pending">pending</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Select Deal or Product</label>
                              <select
                                onChange={(e) =>
                                  setConvertDealProduct(e.target.value)
                                }
                              >
                                <option
                                  value="select Deal Or Product"
                                  disabled
                                  selected
                                >
                                  {convert_deal_Product}
                                </option>
                                <option value="deal">deal</option>
                                <option value="product">product</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-4"></div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Campaign Name</label>
                              <select
                                onChange={(e) => setClient(e.target.value)}
                              >
                                <option
                                  value="select Campaign"
                                  disabled
                                  selected
                                >
                                  {client?.name}
                                </option>
                                {campaigns?.map((val, ind) => {
                                  return (
                                    <option value={val._id}>{val.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="input-details">
                              <label>Category</label>
                              <select
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <input
                                  type="text"
                                  placeholder="search category name"
                                />
                                <option
                                  value="select category"
                                  disabled
                                  selected
                                >
                                  {category?.name}
                                </option>
                                {sub_category?.map((val, ind) => {
                                  return (
                                    <option value={val._id}>{val.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="input-details">
                              <label>
                                {convert_deal_Product === "deal"
                                  ? "Deal Title"
                                  : "Product Title"}
                              </label>
                              <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>
                          </div>

                          {convert_deal_Product === "deal" ? null : (
                            <div className="col-5">
                              <div className="input-details">
                                <label>Product Image</label>
                                <input
                                  type="text"
                                  value={image}
                                  onChange={(e) => setImage(e.target.value)}
                                />
                              </div>
                            </div>
                          )}
                          {convert_deal_Product === "deal" ? null : (
                            <div className="col-7">
                              <div className="row m-0">
                                <div className="col-6">
                                  <div className="input-details">
                                    <label>MRP Price</label>
                                    <input
                                      type="text"
                                      value={mrp}
                                      onChange={(e) => setMRP(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="input-details">
                                    <label>REAL Price</label>
                                    <input
                                      type="text"
                                      value={real}
                                      onChange={(e) => setREAL(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-8">
                            <div className="input-details">
                              <label>
                                {convert_deal_Product === "deal"
                                  ? "Deal Feature"
                                  : "Product Feature"}
                              </label>
                              <input
                                type="text"
                                placeholder="Ex: hello"
                                onChange={(e) => setFeature(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-4 button_height d-flex justify-content-start align-items-center">
                            <button type="button" onClick={featureHandler}>
                              Add Feature
                            </button>
                          </div>
                          <div className="col-12 feature_and_other">
                            <ul>
                              {featureData?.map((val, ind) => {
                                return (
                                  <li key={ind}>
                                    {val}
                                    <span
                                      type="button"
                                      title={`Delete ${val}`}
                                      onClick={() => featureDelete(ind)}
                                    >
                                      <FiXCircle />
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>

                          <div className="col-8">
                            <div className="input-details">
                              <label>Meta Keyword</label>
                              <input
                                type="text"
                                placeholder="Ex: hello"
                                onChange={(e) => setKeyword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-4 button_height d-flex justify-content-start align-items-center">
                            <button type="button" onClick={keywordHandler}>
                              Add Keyword
                            </button>
                          </div>
                          <div className="col-12 feature_and_other">
                            <ul>
                              {keywordData?.map((val, ind) => {
                                return (
                                  <li key={ind}>
                                    {val}
                                    <span
                                      type="button"
                                      title={`Delete ${val}`}
                                      onClick={() => keywordDelete(ind)}
                                    >
                                      <FiXCircle />
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>

                          <div className="col-12">
                            <div className="input-details">
                              <label>Campaign Link</label>
                              <p>
                                <span className="text-danger fw-bold">
                                  Note:
                                </span>{" "}
                                Ex: Tracker link
                                https://mediaxpedia.gotrackier.com/click?campaign_id=24780&pub_id=1603
                              </p>
                              <input
                                type="text"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="input-details">
                              <label>Deal Description</label>
                              <textarea
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-3 mt-4 mb-4">
                            {loading ? (
                              <button
                                type="submit"
                                className="global_form_button"
                                disabled
                              >
                                Please wait...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="global_form_button"
                              >
                                Update Deal
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateDeals;
