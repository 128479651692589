import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const category = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCategory: builder.query({
      query: () => ({
        url: "/category",
        method: "GET",
      }),
    }),

    getByIdCategory: builder.query({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    searchCategory: builder.query({
      query: (key) => {
        console.log("key", key);
        return {
          url: `/category/search/${key}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/category/${id.ids}`,
          method: "PUT",
          body: id.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createCategory: builder.mutation({
      query: (q) => {
        console.log("query : ", q);
        return {
          url: "/category",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useGetByIdCategoryQuery,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useUpdatedCategoryMutation,
  useSearchCategoryQuery
} = category;
