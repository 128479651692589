import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const career = createApi({
  reducerPath: "career",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCareer: builder.query({
      query: () => ({
        url: "/career",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    getByIdCareer: builder.query({
      query: (id) => {
        return {
          url: `/career/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteCareer: builder.mutation({
      query: (id) => {
        return {
          url: `/career/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetByIdCareerQuery,
  useGetCareerQuery,
  useDeleteCareerMutation,
} = career;
