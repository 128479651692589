import React, { useEffect, useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { useCreatePostbackMutation } from "../../App/Slice/postbackSlice";

function UpdatePostback() {
  const guideJson = [
    {
      id: 1,
      title: "{sub_id}",
      description: "Subid Value passed in the tracking link.",
    },
    {
      id: 2,
      title: "{click_id}",
      description: "Transaction id generated for the transaction.",
    },
    {
      id: 3,
      title: "{order_id}",
      description: "The total order value of the transaction.",
    },
    {
      id: 4,
      title: "{conversion_id}",
      description: "The total order value of the transaction.",
    },
    {
      id: 5,
      title: "{sale_amount}",
      description: "The total order value of the transaction.",
    },
    {
      id: 6,
      title: "{currency}",
      description: "The total order value of the transaction.",
    },
    {
      id: 7,
      title: "{campaign_name}",
      description: "The total order value of the transaction.",
    },
    {
      id: 8,
      title: "{transaction_id}",
      description: "The total order value of the transaction.",
    },
    {
      id: 9,
      title: "{status}",
      description: "Status of the Transaction & Order",
    },
  ];

  const eg =
    "For eg: https://api.cashdhundo.com/api/postback/acquisition?sub_id={sub_id}&click_id={click_id}&order_id={order_id}&conversion_id={conversion_id}&sale_amount={sale_amount}&currency={currency}&campaign_name={campaign_name}&transaction_id={transaction_id}";

  const [postback, setPostback] = useState();

  const [createPostback] = useCreatePostbackMutation();

  const extractValue = (property, postback) => {
    const regex = new RegExp(`\\b${property}=([^&]*)`);
    const match = postback.match(regex);
    return match ? match[1] : null;
  };

  const postbackFun = async (e) => {
    e.preventDefault();
    if (!postback) {
      return;
    }

    try {
      const result = await createPostback({
        variables: {
          sub_id: extractValue("sub_id", postback),
          click_id: extractValue("click_id", postback),
          order_id: extractValue("order_id", postback),
          conversion_id: extractValue("conversion_id", postback),
          sale_amount: extractValue("sale_amount", postback),
          currency: extractValue("currency", postback),
          campaign_name: extractValue("campaign_name", postback),
          transaction_id: extractValue("transaction_id", postback),
        },
      });

      console.log("postback result:", result);
    } catch (error) {
      console.error("Error creating postback:", error);
    }
  };

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-9">
                <HeaderBtn urls="/postback-url-click" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Integrate your Postback</h4>
                    <p>Get real time reporting of integrated campaigns</p>
                  </div>
                  <div className="global_form_style">
                    <form onSubmit={postbackFun}>
                      <div className="row">
                        <div className="col-12">
                          <div className="input-details">
                            <textarea
                              placeholder="For eg: https://api.cashdhundo.com/api/postback/acquisition?sub_id={sub_id}&click_id={click_id}&order_id={order_id}&conversion_id={conversion_id}&sale_amount={sale_amount}&currency={currency}&campaign_name={campaign_name}&transaction_id={transaction_id}"
                              onChange={(e) => setPostback(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12 mt-4 mb-4">
                          <button type="submit" className="global_form_button">
                            Integrate Postback
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-12">
                  <div className="guide_section">
                    <h4>Replace the relevant variables in your Postback</h4>
                    <div className="guide_list">
                      <table className="table">
                        <tbody>
                          {guideJson?.map((val, ind) => {
                            return (
                              <tr key={ind} className="table_body_row">
                                <td>{val.title}</td>
                                <td>{val.description}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p>
                    click_id where click_id is your unique click id used to
                    identify your user Example of a Sample Postback: {eg}
                  </p>
                </div>
              </div>
              <div className="col-3 mt-5">
                <div className="guide_side_content">
                  <h4>About cashdhundo Postback:</h4>
                  <p>
                    Now you can integrate your own postback with cashdhundo &
                    receive the transaction updates directly in your own
                    tracking platform. Once a postback url or a postback pixel
                    is added to the account then as soon as the transaction is
                    triggered at the the advertiser’s end the conversion data is
                    pushed to your tracking software along in realtime so you
                    can directly utlise this data & show the transactions
                    information to your users, This functionality has multiple
                    use cases like you can use this information, to reward your
                    users, run programtic advertising campaign, run incentive
                    program & alot more. cashdhundo supports GET request for Global
                    Postback & offers 3 different types of pixels: iFrame,
                    image, and postback, you can add multiple parameters to your
                    postback to receive details like sale amount, commission,
                    transaction id, prouct type etc. The postback is fired for
                    new transactions only however the Reports API can be used
                    along with the Postback to reconcile the data & update the
                    status of the transactions. cashdhundo postback is fully
                    compatible with all the popular tracking platforms available
                    including Hasoffers, Cake, Affilise, Trakier. Here are the
                    steps to enable sub user tracking via postback:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePostback;
