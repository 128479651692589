import React, { useState } from "react";
import Nav from "../../Components/Nav";
import { Link } from "react-router-dom";
import {
  FiArrowLeft,
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiPlus,
  FiTrash2,
} from "react-icons/fi";
import { toast } from "react-toastify";
import AddSubCategory from "./AddSubCategory";
import UpdateSubCategory from "./UpdateSubCategory";
import {
  useDeletesubCategoryMutation,
  useGetsubCategoryQuery,
} from "../../App/Slice/subCategorySlice";
import Heading from "../../Components/Heading";

function AllSubCategoy() {
  const { data, isLoading } = useGetsubCategoryQuery();
  const category = data?.sub_category;

  const [udpateID, setUpdateID] = useState();

  const [deleteCategory] = useDeletesubCategoryMutation();

  const deleteHandler = async (id) => {
    const result = await deleteCategory(id);
    const message = result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const updateData = (id) => {
    setUpdateID(id);
  };

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <div className="update_add_button d-flex justify-content-between mb-3 mt-3">
              <Link to="/" type="button" className="global_btn">
                <FiArrowLeft /> <span>Back</span>
              </Link>
              <a
                type="button"
                className="global_btn"
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                role="button"
              >
                <FiPlus /> <span>Add New Category</span>
              </a>
            </div>

            <Heading title="Sub Category" />

            <div className="col_out_side">
              <table className="table table-striped">
                <thead className="table_head">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Category</th>
                    <th scope="col">Sub Category</th>
                    <th scope="col">Status</th>
                    <th scope="col">Others</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <tbody>
                    {category?.map((val, ind) => {
                      const { _id, name, status, createdAt, category } =
                        val;
                      return (
                        <tr key={ind} className="table_body_row">
                          <th scope="row">{createdAt?.slice(0, 10)}</th>
                          <td>{category?.name}</td>
                          <td>{name}</td>
                          <td>
                            {status === "success" ? (
                              <span className="success_status" type="button">
                                {status}
                              </span>
                            ) : (
                              <span className="failed_status" type="button">
                                {status}
                              </span>
                            )}
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-dark btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FiMoreVertical />
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <Link to="" title={`View ${name}`}>
                                    <FiEye />
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    data-bs-toggle="modal"
                                    href="#exampleModalToggleUpdate"
                                    role="button"
                                    title={`Edit ${name}`}
                                    onClick={() => updateData(_id)}
                                  >
                                    <FiEdit />
                                  </a>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    title={`Delete ${name}`}
                                    onClick={() => deleteHandler(_id)}
                                  >
                                    <FiTrash2 />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <AddSubCategory />
      <UpdateSubCategory udpateID={udpateID} />
    </>
  );
}

export default AllSubCategoy;
