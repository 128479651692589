import React, { useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { FiXCircle } from "react-icons/fi";
import { useCreateCampaignMutation } from "../../App/Slice/campaignSlice";
import { toast } from "react-toastify";

function AddStore() {
  const [name, setName] = useState();
  const [logo, setLogo] = useState();
  const [client_offer, setClientOffer] = useState();
  const [cashback_rates, setCashbackRates] = useState();
  const [keyword, setKeyword] = useState();
  const [searchKey, setSearchKey] = useState();
  const [description, setDescription] = useState();
  const [cashback_date, setCashbackDate] = useState();
  const [avg_tracking_speed, setAvgTrackingSpeed] = useState();
  const [cashback_claim_time, setCashbackClaimTime] = useState();
  const [link, setLink] = useState();

  const [calc, setCalc] = useState();
  const [flat, setFalt] = useState();

  const calculations = [flat, calc];

  const [createCampaign, isLoading] = useCreateCampaignMutation();
  const loading = isLoading?.isLoading;


  //keyword Arrary Add
  const [keywordData, setKeywordData] = useState([]);
  const keywordHandler = () => {
    const fe = keyword;
    const addKeyword = [...keywordData, fe];
    setKeywordData(addKeyword);
  };
  //keyword Array Delete
  const keywordDelete = (id) => {
    const deleteKeyword = keywordData.filter((ele, ind) => {
      return ind !== id;
    });
    setKeywordData(deleteKeyword);
  };

  //Search Arrary Add
  const [SearchData, setSearchData] = useState([]);
  const SearchHandler = () => {
    const fe = searchKey;
    const addSearch = [...SearchData, fe];
    setSearchData(addSearch);
  };
  //Search Array Delete
  const SearchDelete = (id) => {
    const deleteSearch = SearchData.filter((ele, ind) => {
      return ind !== id;
    });
    setSearchData(deleteSearch);
  };

  //cashback rates Add
  const [cashbackData, setCashbackData] = useState([]);
  const cashbackHandler = () => {
    const fe = cashback_rates;
    const addcashback = [...cashbackData, fe];
    setCashbackData(addcashback);
  };
  //cashback rates Delete
  const cashbackDelete = (id) => {
    const deletecashback = cashbackData?.filter((ele, ind) => {
      return ind !== id;
    });
    setCashbackData(deletecashback);
  };

  const createPostHandler = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      logo: logo,
      client_offer: client_offer,
      calc: calculations,
      cashback_date: cashback_date,
      avg_tracking_speed: avg_tracking_speed,
      cashback_claim_time: cashback_claim_time,
      meta_keyword: keywordData,
      search_keyword: SearchData,
      cashback_rates: cashbackData,
      description:description,
      link: link,
    };
    const result = await createCampaign(data);
    console.log("result", result);
    const message = result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href="/all-campaign"
      }, 1000);
    }
  };

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <HeaderBtn urls="/all-campaign" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Add New Campaigns</h4>
                  </div>
                  <div className="global_form_style">
                    <form onSubmit={createPostHandler}>
                      <div className="row w-85 mx-auto">
                        <div className="col-4">
                          <div className="input-details">
                            <label>Campaign Name</label>
                            <input
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="input-details">
                            <label>Campaign Logo</label>
                            <input
                              type="text"
                              onChange={(e) => setLogo(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="input-details">
                            <label>Cashdhundo Cashback</label>
                            <p>
                              <span className="text-danger fw-bold">Note:</span>{" "}
                              Ex: Get 59% OFF
                            </p>
                            <input
                              type="text"
                              onChange={(e) => setClientOffer(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-6">
                          <div className="input-details">
                            <label>Cashdhundo Cashback</label>
                            <p>
                              <span className="text-danger fw-bold">Note:</span>{" "}
                              Ex: Only Number Input LIKE 10% cashback but write
                              down only 10 <br />
                              If ZF = Flat <br /> If FP = Flat PercentAge <br />{" "}
                              If ZU = Upto <br /> If UP = Upto PercentAge
                            </p>
                            <span className="w-100 d-flex justify-content-between">
                              <select onChange={(e) => setFalt(e.target.value)}>
                                <option
                                  value="cashback calculation"
                                  selected
                                  disabled
                                >
                                  cashback calculation
                                </option>
                                <option value="ZF">ZF</option>
                                <option value="FP">FP</option>
                                <option value="ZU">ZU</option>
                                <option value="UP">UP</option>
                                <option value="Paused">Paused</option>
                              </select>
                              <input
                                type="text"
                                placeholder="10"
                                onChange={(e) => setCalc(e.target.value)}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-6"></div>

                        <div className="col-4">
                          <div className="input-details">
                            <label>Estimated Cashback Date</label>
                            <input
                              type="date"
                              onChange={(e) => setCashbackDate(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="input-details">
                            <label>Avg Tracking Speed</label>
                            <input
                              type="text"
                              placeholder="2-3 days"
                              onChange={(e) =>
                                setAvgTrackingSpeed(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="input-details">
                            <label>Avg Cashback Claim Time</label>
                            <input
                              type="text"
                              placeholder="90 days"
                              onChange={(e) =>
                                setCashbackClaimTime(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-8">
                          <div className="input-details">
                            <label>Cashback Rates</label>
                            <input
                              type="text"
                              onChange={(e) => setCashbackRates(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4 button_height d-flex justify-content-start align-items-center">
                          <button type="button" onClick={cashbackHandler}>
                            Add Cashback Rate
                          </button>
                        </div>
                        <div className="col-12 feature_and_other">
                          <ul>
                            {cashbackData?.map((val, ind) => {
                              return (
                                <li key={ind}>
                                  {val}
                                  <span
                                    type="button"
                                    title={`Delete ${val}`}
                                    onClick={() => cashbackDelete(ind)}
                                  >
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="col-8">
                          <div className="input-details">
                            <label>Searching Keyword</label>
                            <input
                              type="text"
                              onChange={(e) => setSearchKey(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4 button_height d-flex justify-content-start align-items-center">
                          <button type="button" onClick={SearchHandler}>
                            Add Search Keyword
                          </button>
                        </div>
                        <div className="col-12 feature_and_other">
                          <ul>
                            {SearchData?.map((val, ind) => {
                              return (
                                <li key={ind}>
                                  {val}
                                  <span
                                    type="button"
                                    title={`Delete ${val}`}
                                    onClick={() => SearchDelete(ind)}
                                  >
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="col-8">
                          <div className="input-details">
                            <label>Meta Keyword</label>
                            <input
                              type="text"
                              onChange={(e) => setKeyword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4 button_height d-flex justify-content-start align-items-center">
                          <button type="button" onClick={keywordHandler}>
                            Add Keyword
                          </button>
                        </div>
                        <div className="col-12 feature_and_other">
                          <ul>
                            {keywordData?.map((val, ind) => {
                              return (
                                <li key={ind}>
                                  {val}
                                  <span
                                    type="button"
                                    title={`Delete ${val}`}
                                    onClick={() => keywordDelete(ind)}
                                  >
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="col-12">
                          <div className="input-details">
                            <label>Campaign Link</label>
                            <p>
                              <span className="text-danger fw-bold">Note:</span>{" "}
                              Ex: Tracker link
                              https://mediaxpedia.gotrackier.com/click?campaign_id=24780&pub_id=1603
                            </p>
                            <input
                              type="text"
                              onChange={(e) => setLink(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-details">
                            <label>Meta Description</label>
                           <textarea onChange={(e) => setDescription(e.target.value)}></textarea>
                          </div>
                        </div>
                        <div className="col-3 mt-4 mb-4">
                          {loading ? (
                            <button
                              type="submit"
                              className="global_form_button"
                              disabled
                            >
                              Please wait...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="global_form_button"
                            >
                              Create Campaign
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddStore;
