import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Protected from "./Secure/Protected";

import { Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "./Secure/PageNotFound";

import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import UpdatePostback from "./Views/Postback/UpdatePostback";
import ClickPostbackURL from "./Views/Postback/ClickPostbackURL";
import Campaign from "./Pages/Campaign";
import AllStore from "./Views/Campaign/AllStore";
import AddStore from "./Views/Campaign/AddStore";
import UpdateStore from "./Views/Campaign/UpdateStore";
import AllDeals from "./Views/Deals/AllDeals";
import AddDeals from "./Views/Deals/AddDeals";
import UpdateDeals from "./Views/Deals/UpdateDeals";
import AllCategoy from "./Views/Category/AllCategoy";
import AllSubCategoy from "./Views/SubCategory/AllSubCategoy";
import AllCoupon from "./Views/Coupon/AllCoupon";
import AddCoupon from "./Views/Coupon/AddCoupon";
import UpdateCoupon from "./Views/Coupon/UpdateCoupon";
import SignIn from "./Profile/SignIn";
import AdminUser from "./Pages/AdminUser";
import AllUsers from "./Views/UserData/AllUsers";
import MissingReport from "./Views/Report/MissingReport";
import AllCashback from "./Views/Cashback/AllCashback";
import Media from "./Views/Media/Media";
import SignUp from "./Profile/SignUp";
import UpdateCashback from "./Views/Cashback/UpdateCashback";
import AllBlog from "./Views/Blog/AllBlog";
import AddBlog from "./Views/Blog/AddBlog";
import UpdatedBlog from "./Views/Blog/UpdatedBlog";
import AllBanner from "./Views/Banner/AllBanner";
import Contact from "./Views/Support/Contact";
import Career from "./Views/Support/Career";
import AllWithdrawl from "./Views/Withdrawl/AllWithdrawl";
import UpdateWithdrawl from "./Views/Withdrawl/UpdateWithdrawl";
import SuccessWithdrawl from "./Views/Withdrawl/SuccessWithdrawl";
import PendingWithdrawl from "./Views/Withdrawl/PendingWithdrawl";
import RejectWithdrawl from "./Views/Withdrawl/RejectWithdrawl";
import PendingCashback from "./Views/Cashback/PendingCashback";
import SuccessCashback from "./Views/Cashback/SuccessCashback";
import RejectCashback from "./Views/Cashback/RejectCashback";
import Support from "./Views/Support/Support";
import Partner from "./Views/Support/Partner";

function App() {
  const url = useLocation().pathname;
  
  return (
    <>
      {url === "/admin-login" || url ==="/admin-signup" ? null : <Navbar />}

      <Routes>
        {/* <Route path="/" element={<Protected ProtectRoute={Home} />} /> */}
        <Route path="/" element={<Protected ProtectRoute={Home} />} />
        {/* <Route
          path={"/user/profile"}
          element={<Protected ProtectRoute={Profile} />}
        /> */}
        <Route path="/admin-user" element={<AdminUser />} />
        <Route path="/admin-login" element={<SignIn />} />
        <Route path="/admin-signup" element={<SignUp />} />

        <Route path="/users" element={<AllUsers />} />

        <Route path="/postback-update" element={<Protected ProtectRoute={UpdatePostback} />} />
        <Route path="/postback-url-click" element={<Protected ProtectRoute={ClickPostbackURL} />} />
        <Route path="/transaction-report" element={<Protected ProtectRoute={ClickPostbackURL} />} />

        <Route path="/campaign" element={<Protected ProtectRoute={Campaign} />} />
        <Route path="/all-campaign" element={<Protected ProtectRoute={AllStore} />} />
        <Route path="/add-campaign" element={<Protected ProtectRoute={AddStore} />} />
        <Route path="/update-campaign/:id" element={<Protected ProtectRoute={UpdateStore} />} />

        <Route path="/all-deals" element={<Protected ProtectRoute={AllDeals} />} />
        <Route path="/add-deals" element={<Protected ProtectRoute={AddDeals} />} />
        <Route path="/update-deals/:id" element={<Protected ProtectRoute={UpdateDeals} />} />

        <Route path="/all-coupons" element={<Protected ProtectRoute={AllCoupon} />} />
        <Route path="/add-coupons" element={<Protected ProtectRoute={AddCoupon} />} />
        <Route path="/update-coupons/:id" element={<Protected ProtectRoute={UpdateCoupon} />} />

        <Route path="/category" element={<Protected ProtectRoute={AllCategoy} />} />
        <Route path="/sub-category" element={<Protected ProtectRoute={AllSubCategoy} />} />

        <Route path="/missing-report" element={<Protected ProtectRoute={MissingReport} />} />

        <Route path="/cashback-report" element={<Protected ProtectRoute={AllCashback} />} />
        <Route path="/update-cashback/:id" element={<Protected ProtectRoute={UpdateCashback} />} />
        <Route path="/pending-cashback" element={<Protected ProtectRoute={PendingCashback} />} />
        <Route path="/success-cashback" element={<Protected ProtectRoute={SuccessCashback} />} />
        <Route path="/reject-cashback" element={<Protected ProtectRoute={RejectCashback} />} />

        <Route path="/blogs" element={<Protected ProtectRoute={AllBlog} />} />
        <Route path="/add-blogs" element={<Protected ProtectRoute={AddBlog} />} />
        <Route path="/update-blogs/:id" element={<Protected ProtectRoute={UpdatedBlog} />} />

        <Route path="/withdrawl-money" element={<Protected ProtectRoute={AllWithdrawl} />} />
        <Route path="/update-withdrawl-money/:id" element={<Protected ProtectRoute={UpdateWithdrawl} />} />
        <Route path="/success-withdrawl" element={<Protected ProtectRoute={SuccessWithdrawl} />} />
        <Route path="/pending-withdrawl" element={<Protected ProtectRoute={PendingWithdrawl} />} />
        <Route path="/reject-withdrawl" element={<Protected ProtectRoute={RejectWithdrawl} />} />

        <Route path="/contact" element={<Protected ProtectRoute={Contact} />} />
        <Route path="/career" element={<Protected ProtectRoute={Career} />} />
        <Route path="/support" element={<Protected ProtectRoute={Support} />} />
        <Route path="/partner" element={<Protected ProtectRoute={Partner} />} />

        <Route path="/banner" element={<Protected ProtectRoute={AllBanner} />} />

        <Route path="/upload-media" element={<Protected ProtectRoute={Media} />} />

        <Route path="/404-error" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
