import React, { useEffect, useState } from "react";
import HeaderBtn from "../../Components/HeaderBtn";
import Heading from "../../Components/Heading";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import Nav from "../../Components/Nav";
import { AuthCheck } from "../../Secure/Helper";
import { useGetWithdrawlQuery } from "../../App/Slice/withdrawlSlice";

function AllWithdrawl() {
  const { data, isLoading } = useGetWithdrawlQuery();
  const withdrawMoney = data?.withdraw_money;
  const totalcashback = data?.withdraw_money_length;

  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = withdrawMoney?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el.Order_ID.toLowerCase().includes(search);
    }
  });

  useEffect(() => {
    AuthCheck();
  }, []);

  return (
    <div className="container-fluid Dashboard-Round">
      <div className="row">
        <Nav />
        <div className="col-lg-10">
          <HeaderBtn
            urls="/"
            addUrls="/add-missing-withdrawl"
            addTitle="Add Missing Withdrawl"
          />
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <Heading title="All Withdrawl Request" length={totalcashback} />
              <div className="user_search">
                <form>
                  <div className="input-deatils">
                    <input
                      type="search"
                      placeholder="search order id "
                      onChange={inputHandler}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col_out_side">
            <table className="table table-striped">
              <thead className="table_head">
                <tr>
                  <th scope="col" title="Create User Date">
                    CUD
                  </th>
                  <th scope="col">Mode</th>
                  <th scope="col">Name</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Withdrawl Amount</th>
                  <th scope="col">UPI</th>
                  <th scope="col">Mobile No.</th>
                  <th scope="col">Country</th>
                  <th scope="col">Account No</th>
                  <th scope="col">Bank City</th>
                  <th scope="col">Bank Branch</th>
                  <th scope="col">IFSC</th>
                  <th scope="col">PAN</th>
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>
                  <th scope="col">Others</th>
                </tr>
              </thead>
              {
                isLoading ? <p>Fetching data...</p> :
              <tbody>
                {filteredData?.map((val, ind) => {
                  const {
                    _id,
                    createdAt,
                    status,
                    Order_ID,
                    amount,
                    mode,
                    user,
                    by_bank,
                    by_upi,
                  } = val;
                  return (
                    <tr key={ind} className="table_body_row">
                      <th scope="row">{createdAt?.slice(0, 10)}</th>
                      <td>{mode==="UPI" ? 'UPI' : "Bank"}</td>
                      <td>{mode === "UPI" ? by_upi?.name : by_bank?.name}</td>
                      <td>{Order_ID}</td>
                      <td>{amount}</td>
                      <td>{mode === "UPI" ? by_upi?.upi_detail : "NaN"}</td>
                      <td>{mode === "UPI" ? by_upi?.phone : user?.phone}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.country_name}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.account_no}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.bank_city}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.bank_branch}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.ifsc_code}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.pan_number}</td>
                      <td>{mode === "UPI" ? "NaN" : by_bank?.address}</td>
                      <td>
                        {status === "approve" ? (
                          <span className="success_status" type="button">
                            {status}
                          </span>
                        ) : status === "pending" ? (
                          <span className="pending_status" type="button">
                            {status}
                          </span>
                        ) : (
                          <span className="failed_status" type="button">
                            {status}
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            className="btn btn-dark btn-sm dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FiMoreVertical />
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to="" title={`View title`}>
                                <FiEye />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/update-withdrawl-money/${_id}`}
                                title={`Edit title`}
                              >
                                <FiEdit />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                title={`Delete title`}
                                // onClick={() => deleteHandler(_id)}
                              >
                                <FiTrash2 />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllWithdrawl;
