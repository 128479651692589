import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const media = createApi({
  reducerPath: "media",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getMedia: builder.query({
      query: () => ({
        url: "/media",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    deleteMedia: builder.mutation({
      query: (id) => {
        return {
          url: `/media/cashdhundo/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createMedia: builder.mutation({
      query: (q) => {
        console.log("query : ", q);
        return {
          url: "/media",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {useGetMediaQuery, useCreateMediaMutation, useDeleteMediaMutation} = media;
