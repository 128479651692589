import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const banner = createApi({
  reducerPath: "banner",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => ({
        url: "/banner",
        method: "GET",
      }),
    }),

    getByIdBanner: builder.query({
      query: (id) => {
        return {
          url: `/banner/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteBanner: builder.mutation({
      query: (id) => {
        return {
          url: `/banner/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedBanner: builder.mutation({
      query: (id) => {
        console.log("id", id);
        return {
          url: `/banner/${id.ids}`,
          method: "PUT",
          body: id.data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createBanner: builder.mutation({
      query: (q) => {
        console.log("query : ", q);
        return {
          url: "/banner",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

  }),
});

export const {
  useGetBannerQuery,
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useUpdatedBannerMutation,
  useGetByIdBannerQuery
} = banner;
