import React from "react";
import Nav from "../Components/Nav";
import HeaderBtn from "../Components/HeaderBtn";
import Heading from "../Components/Heading";
import { Link } from "react-router-dom";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { useAllUserDataQuery } from "../App/Slice/authSlice";

function AdminUser() {
  const { data } = useAllUserDataQuery();
  const user = data?.users;

  const onlyAdmin = user?.filter((item) => item.role.includes("admin"));
  
  return (
    <div className="container-fluid Dashboard-Round">
      <div className="row">
        <Nav />
        <div className="col-lg-10">
          <HeaderBtn urls="/" addUrls="/add-admin-user" addTitle="Add User" />
          <Heading title="All Admin user" />
          <div className="col_out_side">
            <table className="table table-striped">
              <thead className="table_head">
                <tr>
                  <th scope="col" title="Create User Date">
                    CUD
                  </th>
                  <th scope="col">UID</th>
                  <th scope="col">Sub ID</th>
                  <th scope="col">Username</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Access</th>
                  <th scope="col">Status</th>
                  <th scope="col">Others</th>
                </tr>
              </thead>
              <tbody>
                {onlyAdmin?.map((val, ind) => {
                  const {
                    _id,
                    username,
                    sub_id,
                    email,
                    phone,
                    role,
                    createdAt,
                  } = val;
                  return (
                    <tr className="table_body_row">
                      <th scope="row">{createdAt?.slice(0, 10)}</th>
                      <td>{_id}</td>
                      <td>
                        <Link to="">{sub_id}</Link>
                      </td>
                      <td>{username}</td>
                      <td>{email}</td>
                      <td>{phone}</td>
                      <td>
                        {role === "admin" ? (
                          <span className="admin_status" type="button">
                            {role}
                          </span>
                        ) : (
                          <span className="normal_status" type="button">
                            {role}
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <Link
                          to=""
                          type="button"
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Link
                        </Link>
                      </td>
                      <td>
                        <div class="btn-group">
                          <button
                            class="btn btn-dark btn-sm dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FiMoreVertical />
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <Link to="" title={`View title`}>
                                <FiEye />
                              </Link>
                            </li>
                            <li>
                              <Link to={`/update-deals/`} title={`Edit title`}>
                                <FiEdit />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                title={`Delete title`}
                                // onClick={() => deleteHandler(_id)}
                              >
                                <FiTrash2 />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUser;
