import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateLoginUserMutation } from "../App/Slice/authSlice";

function SignIn() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [check, setCheck] = useState();

  const [createUsers, isLoading] = useCreateLoginUserMutation();
  const loading = isLoading?.isLoading;

  const nevigate = useNavigate();
  let storage = JSON.parse(localStorage.getItem("admin-user"));

  useEffect(() => {
    if (storage) {
      return nevigate("/");
    } else {
      return;
    }
  }, []);

  const signInFormSubmit = async (e) => {
    e.preventDefault();
    const data = { email: email, password: password };
    if (
      email === "" ||
      (email === "undefined" && password === "") ||
      (email === "undefined" && password === "") ||
      (password === "undefined" && check === "") ||
      check === undefined
    ) {
      return alert("all fields are required!");
    }
    const result = await createUsers(data);
    const message = result?.data?.message;
    const user = result?.data?.user;
    // console.log("users :", user);
    if (!user) {
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      return;
    } else if (user.role === "normal") {
      toast.error("your credentials is wrong!", {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      return;
    } else {
      toast.success(`Hey ${user?.username} ${message}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });

      localStorage.setItem("admin-user", JSON.stringify(user));
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    }
  };

  return (
    <>
      <div className="container-fluid p-0 form-bg-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-12 p-0">
            <div className="login-form">
              <form onSubmit={signInFormSubmit}>
                <div className="input-fields text-center mb-4">
                  <img src="https://api.cashdhundo.com/public/uploads/1704013984140logo.png" alt="cashdhundo" />
                </div>
                <div className="input-fields">
                  <label>E-mail</label>
                  <input
                    type="text"
                    placeholder="abc@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="********"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields checkbox_input">
                  <input
                    type="checkbox"
                    onChange={(e) => setCheck(e.target.value)}
                  />
                  <p>Remember me</p>
                </div>
                <div className="forgot-password forgot_password_dashboard d-flex justify-content-end mb-2">
                  <Link to="">Forgot Password</Link>
                </div>
                <div className="input-fields submit-button">
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Please Wait..." : "SIGN IN"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
