import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const auth = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    createLoginUser: builder.mutation({
      query: (body) => {
        return {
          url: "/user/login",
          method: "POST",
          body: body,
        };
      },
    }),

    createRegisterUser: builder.mutation({
      query: (body) => {
        return {
          url: "/user/register",
          method: "POST",
          body: body,
        };
      },
    }),

    UpdateUserProfile: builder.mutation({
      query: (body) => {
        return {
          url: "/user/update-profile",
          method: "PUT",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: body,
        };
      },
    }),

    EmailSendForVerify: builder.mutation({
      query: (email) => {
        return {
          url: "/user/email-send-verify",
          method: "POST",
          body: email,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    NormalUserVerify: builder.query({
      query: () => {
        return {
          url: "/user/normal-user",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    AllUserData: builder.query({
      query: () => {
        return {
          url: "/user/all-users",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    VerifyEmail: builder.mutation({
      query: (params) => {
        return {
          url: `/user/verify-email?id=${params}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    ForgotPasswordMailSend: builder.mutation({
      query: (body) => {
        return {
          url: "/user/forgot-mail-send",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        };
      },
    }),

    changeUserPassword: builder.mutation({
      query: (data) => {
        console.log("body :", data);
        const { id, token, password, password_confirmation } = data;
        const bodies = {
          password: password,
          password_confirmation: password_confirmation,
        };
        return {
          url: `/user/password-forgot/${id}/${token}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodies,
        };
      },
    }),
  }),
});

export const {
  useCreateLoginUserMutation,
  useCreateRegisterUserMutation,
  useUpdateUserProfileMutation,
  useEmailSendForVerifyMutation,
  useVerifyEmailMutation,
  useForgotPasswordMailSendMutation,
  useChangeUserPasswordMutation,
  useNormalUserVerifyQuery,
  useAllUserDataQuery,
} = auth;
