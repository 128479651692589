import React, { useEffect, useState } from "react";
import { useGetCategoryQuery } from "../../App/Slice/categorySlice";
import { toast } from "react-toastify";
import {
  useGetByIdsubCategoryQuery,
  useUpdatedsubCategoryMutation,
} from "../../App/Slice/subCategorySlice";

function UpdateSubCategory(udpateID) {
  const ids = udpateID?.udpateID;

  const [category, setCategory] = useState();
  const [name, setName] = useState();
  const [status, setStatus] = useState();

  const { data: getCategory } = useGetByIdsubCategoryQuery(ids);
  const cateID = getCategory?.category;

  const { data: Pcategory } = useGetCategoryQuery();
  const mCate = Pcategory?.category;

  useEffect(() => {
    const { name, status, category } = cateID || {};
    setCategory(category);
    setName(name);
    setStatus(status);
  }, [cateID]);

  const [updateCategory, isLoading] = useUpdatedsubCategoryMutation();
  const loading = isLoading?.isLoading;

  const updatedCategoryHandler = async (e) => {
    e.preventDefault();

    const data = {
      category: category,
      name: name,
      status: status,
    };

    const result = await updateCategory({ ids, data });
    const message = await result?.data;
    if (message?.status === "failed") {
      toast.error(result?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleUpdate"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleUpdateLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleUpdateLabel">
                Update {name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={updatedCategoryHandler}>
                <div className="row w-85 mx-auto">
                  <div className="col-12">
                    <div className="input-details">
                      <label>Status</label>
                      <select onChange={(e) => setStatus(e.target.value)}>
                        <option value="select status">{status}</option>
                        <option value="success">success</option>
                        <option value="pending">pending</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-details">
                      <label>Parent Category</label>
                      <select onChange={(e) => setCategory(e.target.value)}>
                        <option value="select category">
                          {category?.name}
                        </option>
                        {mCate?.map((val, ind) => {
                          return <option value={val._id}>{val.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-details">
                      <label>Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-4 mb-4">
                    {loading ? (
                      <button
                        type="submit"
                        className="global_form_button"
                        disabled
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button type="submit" className="global_form_button">
                        Update Category
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateSubCategory;
