import React, { useEffect, useState } from "react";
import Nav from "../../Components/Nav";
import HeaderBtn from "../../Components/HeaderBtn";
import { useParams } from "react-router-dom";
import {
  useGetByIdCashbackQuery,
  useUpdatedCashbackMutation,
} from "../../App/Slice/cashbackSlice";
import { toast } from "react-toastify";

function UpdateCashback() {
  const params = useParams();
  const { id } = params;

  const [status, setStatus] = useState();
  const [cashback, setCashback] = useState();
  const [client, setClient] = useState();
  const [subId, setSubId] = useState();
  const [saleAmount, setSaleAmount] = useState();
  const [transactionD, setTrnsactionDate] = useState();
  const [transactionId, setTransactionsID] = useState();

  const { data, isLoading } = useGetByIdCashbackQuery(id);
  const cashBack = data?.cashback_history;

  const [updatedCashback] = useUpdatedCashbackMutation();

  const updateHandlerForm = async (e) => {
    e.preventDefault();
    const data = { status: status };
    const result = await updatedCashback({ id, data });
    const message = await result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/cashback-report";
      }, 1000);
    }
  };

  useEffect(() => {
    const {
      cashback,
      client,
      sale_amount,
      status,
      sub_id,
      transaction_date,
      transaction_id,
    } = cashBack || {};
    setStatus(status);
    setCashback(cashback);
    setClient(client);
    setSaleAmount(sale_amount);
    setSubId(sub_id);
    setTrnsactionDate(transaction_date);
    setTransactionsID(transaction_id);
  }, [params, cashBack]);

  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <HeaderBtn urls="/cashback-report" />
                <div className="col_out_side">
                  <div className="global_form_heading mb-3">
                    <h4>Cashback Status Update</h4>
                  </div>
                  {isLoading ? (
                    <p>Fetching data...</p>
                  ) : (
                    <div className="global_form_style">
                      <form onSubmit={updateHandlerForm}>
                        <div className="row w-85 mx-auto">
                          <h6 className="mt-3 mb-3">SUB ID: {subId}</h6>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Cashback Status</label>
                              <select
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="select status" disabled selected>
                                  {status}
                                </option>
                                <option value="pending">pending</option>
                                <option value="approve">approve</option>
                                <option value="rejected">rejected</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Cashback Offer</label>
                              <input
                                type="text"
                                value={cashback}
                                disabled
                                style={{
                                  cursor: "no-drop",
                                  color: "red",
                                  border: "1px solid red",
                                  background: "#ffb2b2",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-4"></div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Campaign Name</label>
                              <input
                                type="text"
                                disabled
                                style={{
                                  cursor: "no-drop",
                                  color: "red",
                                  border: "1px solid red",
                                  background: "#ffb2b2",
                                }}
                                value={client}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Sale Amount</label>
                              <input
                                type="text"
                                disabled
                                style={{
                                  cursor: "no-drop",
                                  color: "red",
                                  border: "1px solid red",
                                  background: "#ffb2b2",
                                }}
                                value={`₹ ${saleAmount}`}
                              />
                            </div>
                          </div>
                          <div className="col-4"></div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Transaction Date</label>
                              <input
                                type="text"
                                disabled
                                style={{
                                  cursor: "no-drop",
                                  color: "red",
                                  border: "1px solid red",
                                  background: "#ffb2b2",
                                }}
                                value={transactionD?.slice(0, 25)}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-details">
                              <label>Transaction ID</label>
                              <input
                                type="text"
                                disabled
                                style={{
                                  cursor: "no-drop",
                                  color: "red",
                                  border: "1px solid red",
                                  background: "#ffb2b2",
                                }}
                                value={transactionId}
                              />
                            </div>
                          </div>
                          <div className="col-4"></div>
                          <div className="col-3 mt-4 mb-4">
                            <button
                              type="submit"
                              className="global_form_button"
                            >
                              Update Cashback Status
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateCashback;
