import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { postback } from "./Slice/postbackSlice";
import { campaign } from "./Slice/campaignSlice";
import { Deals } from "./Slice/dealsSlice";
import { category } from "./Slice/categorySlice";
import { subcategory } from "./Slice/subCategorySlice";
import { coupon } from "./Slice/couponSlice";
import { auth } from "./Slice/authSlice";
import { missingReport } from "./Slice/missingReportSlice";
import { cashback } from "./Slice/cashbackSlice";
import { media } from "./Slice/mediaSlice";
import { blog } from "./Slice/blogSlice";
import { banner } from "./Slice/bannerSlice";
import { contact } from "./Slice/contactSlice";
import { career } from "./Slice/careerSlice";
import { withdrawl } from "./Slice/withdrawlSlice";
import { support } from "./Slice/supportSlice";
import { partner } from "./Slice/partnerSlice";

export const store = configureStore({
  reducer: {
    [auth.reducerPath]: auth.reducer,
    [postback.reducerPath]: postback.reducer,
    [campaign.reducerPath]: campaign.reducer,
    [Deals.reducerPath]: Deals.reducer,
    [category.reducerPath]: category.reducer,
    [subcategory.reducerPath]: subcategory.reducer,
    [coupon.reducerPath]: coupon.reducer,
    [missingReport.reducerPath]: missingReport.reducer,
    [cashback.reducerPath]: cashback.reducer,
    [media.reducerPath]: media.reducer,
    [blog.reducerPath]: blog.reducer,
    [banner.reducerPath]: banner.reducer,
    [contact.reducerPath]: contact.reducer,
    [career.reducerPath]: career.reducer,
    [withdrawl.reducerPath]: withdrawl.reducer,
    [support.reducerPath]: support.reducer,
    [partner.reducerPath]: partner.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      auth.middleware,
      postback.middleware,
      campaign.middleware,
      Deals.middleware,
      category.middleware,
      subcategory.middleware,
      coupon.middleware,
      missingReport.middleware,
      cashback.middleware,
      media.middleware,
      blog.middleware,
      banner.middleware,
      contact.middleware,
      career.middleware,
      withdrawl.middleware,
      support.middleware,
      partner.middleware,
    ]),
});
setupListeners(store.dispatch);
