import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const support = createApi({
  reducerPath: "support",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getSupport: builder.query({
      query: () => ({
        url: "/support",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      }),
    }),

    getByIdSupport: builder.query({
      query: (id) => {
        return {
          url: `/support/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteSupport: builder.mutation({
      query: (id) => {
        return {
          url: `/support/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSupportQuery,
  useDeleteSupportMutation,
} = support;
