import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetByIdBannerQuery,
  useUpdatedBannerMutation,
} from "../../App/Slice/bannerSlice";
import { useGetCampaignQuery } from "../../App/Slice/campaignSlice";

function UpdateBanner(udpateID) {
  const ids = udpateID?.udpateID;

  const [client, setClient] = useState();
  const [image, setImage] = useState();
  const [link, setLink] = useState();
  const [status, setStatus] = useState();

  const { data } = useGetCampaignQuery();
  const cli = data?.client;

  const { data: getCategory, isLoading: getIdLoading } =
    useGetByIdBannerQuery(ids);
  const cateID = getCategory?.Banner;

  useEffect(() => {
    const { client, link, image, status } = cateID || {};
    setImage(image);
    setLink(link);
    setStatus(status);
    setClient(client);
  }, [cateID]);

  const [UpdateBanner, isLoading] = useUpdatedBannerMutation();
  const loading = isLoading?.isLoading;

  const updatedCategoryHandler = async (e) => {
    e.preventDefault();

    const data = {
      client: client,
      link: link,
      image: image,
      status: status,
    };

    const result = await UpdateBanner({ ids, data });
    const message = await result?.data;
    if (message.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleUpdate"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleUpdateLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleUpdateLabel">
                Update
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {getIdLoading ? (
              <p>Fetching data...</p>
            ) : (
              <div className="modal-body">
                <form onSubmit={updatedCategoryHandler}>
                  <div className="row w-85 mx-auto">
                    {image ? (
                      <div className="col-12">
                        <div className="category_image">
                          <img src={image} alt="cashdhundo" />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-12">
                      <div className="input-details">
                        <label>Status</label>
                        <select onChange={(e) => setStatus(e.target.value)}>
                          <option value="select status">{status}</option>
                          <option value="success">success</option>
                          <option value="pending">pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-details">
                        <label>Select Client</label>
                        <select onClick={(e) => setClient(e.target.value)}>
                          <option value="Select Client" disabled selected>
                            {client?.name}
                          </option>
                          {cli?.map((val, ind) => {
                            return (
                              <option key={ind} value={val?._id}>
                                {val?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-details">
                        <label>Image</label>
                        <input
                          type="text"
                          value={image}
                          onChange={(e) => setImage(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-details">
                        <label>Link</label>
                        <input
                          type="text"
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 mt-4 mb-4">
                      {loading ? (
                        <button
                          type="submit"
                          className="global_form_button"
                          disabled
                        >
                          Please wait...
                        </button>
                      ) : (
                        <button type="submit" className="global_form_button">
                          Update Banner
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateBanner;
