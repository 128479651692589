import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN, URL_LINK } from "../../Secure/Helper";

export const campaign = createApi({
  reducerPath: "Campaign",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCampaign: builder.query({
      query: () => ({
        url: "/client",
        method: "GET",
      }),
    }),

    getByIdCampaign: builder.query({
      query: (id) => {
        return {
          url: `/client/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteCampaign: builder.mutation({
      query: (id) => {
        return {
          url: `/client/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    updatedCampaign: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/client/${id}`,
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createCampaign: builder.mutation({
      query: (q) => {
        console.log("query : ", q);
        return {
          url: "/client",
          method: "POST",
          body: q,
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useDeleteCampaignMutation,
  useGetByIdCampaignQuery,
  useUpdatedCampaignMutation
} = campaign;
