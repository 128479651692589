import React from "react";
import { useState } from "react";
import { useUploadCSVFileMutation } from "../../App/Slice/postbackSlice";
import { toast } from "react-toastify";

function UploadCSV() {
  const [csv, setCSV] = useState();
  const [uploadCSVFile, isLoading] = useUploadCSVFileMutation();
  const loading = isLoading?.isLoading;

  const uploadCSVHandler = async (e) => {
    e.preventDefault();
    //formData
    const formData = new FormData();
    formData.append("file", csv);

    const result = await uploadCSVFile(formData);
    const message = result?.data;
    if (message?.status === "failed") {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(message?.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1200);
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleUpload"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleUploadLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleUploadLabel">
                Upload CSV File
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={uploadCSVHandler}>
                <div className="row w-85 mx-auto">
                  <div className="col-12">
                    <div className="input-details">
                      <label>Upload CSV File</label>
                      <p className="mb-2"><span className="text-danger">Note: </span> </p>
                      <input
                        type="file"
                        placeholder="Enter Url"
                        onChange={(e) => setCSV(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-4 mb-4">
                    {loading ? (
                      <button
                        type="submit"
                        className="global_form_button"
                        disabled
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button type="submit" className="global_form_button">
                        Upload CSV
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadCSV;
