import React, { useEffect } from "react";
import Nav from "../Components/Nav";
import { AuthCheck } from "../Secure/Helper";

function Home() {

  useEffect(() =>{
    AuthCheck()
  },[])
  
  return (
    <>
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <Nav />
          <div className="col-lg-10">
            <h4>Dashboard</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
